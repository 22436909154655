import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Draft, produce } from "immer";
import { shallow } from "zustand/shallow";
import { useWindowEvent } from "@mantine/hooks";

export interface BundleItem {
	productId: number;
	variationId?: number;
}

export interface CartItem {
	type: string;
	productId: number;
	variationId?: number;
	bundleItems: BundleItem[];
	quantity: number;
	total: number;
}

export interface CartState {
	cartItems: CartItem[];
	subtotal: number;
	itemAdded: boolean;
	closeOnClickOutside: boolean;
	set: (fn: (draft: Draft<CartState>) => Draft<CartState> | void) => void;
}

export interface CartActions {
	setItemAdded: (value: boolean) => void;
	updateCart: (params: {
		type: string;
		productId: number;
		variationId?: number;
		bundleItems: BundleItem[];
		quantity: number | ((currentQuantity: number) => number);
		price: number;
	}) => void;
	clearCart: () => void;
	setCloseOnClickOutside: (boolean: boolean) => void;
	getCartItem: (props: {
		type: string;
		productId: number;
		variationId?: number;
		bundleItems: BundleItem[];
	}) => CartItem | null;
}

const createPersistedStore = (createStore, persistOptions) => {
	const usePersistedStore = create(persist(createStore, persistOptions));

	const useMounted = create(() => false);

	const initialState = createStore(
		() => {},
		() => createStore(() => {})
	);

	const useStore = (selector, compare) => {
		const store = usePersistedStore(selector, compare);
		const mounted = useMounted();

		useEffect(() => {
			if (!mounted) {
				useMounted.setState(true);
			}
		}, []);

		return mounted ? store : selector(initialState);
	};

	return useStore;
};

export const useCartStore = createPersistedStore(
	(set): CartState => ({
		cartItems: [],
		subtotal: 0,
		itemAdded: false,
		closeOnClickOutside: true,
		set: (fn) => set(produce(fn)),
	}),
	{
		name: "loona-cart",
	}
);

export const useCartActions = (): CartActions => {
	const set = useCartStore((state) => state.set, shallow);
	const cartItems = useCartStore((state) => state.cartItems, shallow);

	const areBundleItemsEqual = (arr1, arr2) => {
		if (!arr1 || !arr2) return false;
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (
				arr1[i].productId !== arr2[i].productId ||
				arr1[i].variationId !== arr2[i].variationId
			) {
				return false;
			}
		}
		return true;
	};

	const updateCart = ({
		type,
		productId,
		variationId,
		bundleItems,
		quantity,
		price,
	}) =>
		set((state) => {
			const itemIndex = state.cartItems.findIndex(
				(item) =>
					item.type === type &&
					item.productId === productId &&
					item.variationId === variationId &&
					areBundleItemsEqual(item.bundleItems, bundleItems)
			);

			let newQuantity =
				typeof quantity === "function"
					? quantity(
							itemIndex !== -1 ? state.cartItems[itemIndex].quantity : 0
					  )
					: quantity;

			if (newQuantity <= 0) {
				return {
					cartItems: state.cartItems.filter(
						(item, index) => index !== itemIndex
					),
					subtotal: state.cartItems
						.filter((item, index) => index !== itemIndex)
						.reduce((total, item) => total + item.total, 0),
				};
			}

			let updatedCartItems;
			let shouldSetItemAdded;

			if (itemIndex !== -1) {
				// Item already exists in the cart
				const currentItem = state.cartItems[itemIndex];

				if (newQuantity > currentItem.quantity) {
					// Quantity increased
					updatedCartItems = [...state.cartItems];
					updatedCartItems[itemIndex] = {
						...updatedCartItems[itemIndex],
						quantity: newQuantity,
						total: price * newQuantity,
					};

					// Set itemAdded to true because quantity has been increased
					shouldSetItemAdded = true;
				} else {
					// Quantity did not increase, no change to itemAdded
					updatedCartItems = [...state.cartItems];
					updatedCartItems[itemIndex] = {
						...updatedCartItems[itemIndex],
						quantity: newQuantity,
						total: price * newQuantity,
					};
				}
			} else {
				// Item doesn't exist in the cart, add it
				const newCartItem: CartItem = {
					type,
					productId,
					variationId,
					bundleItems,
					quantity: newQuantity,
					total: price * newQuantity,
				};
				updatedCartItems = [...state.cartItems, newCartItem];

				// Set itemAdded to true because a new item is added
				shouldSetItemAdded = true;
			}

			const subtotal = updatedCartItems.reduce(
				(total, item) => total + item.total,
				0
			); // Calculate the subtotal

			(state.cartItems = updatedCartItems),
				(state.subtotal = subtotal),
				shouldSetItemAdded
					? (state.itemAdded = shouldSetItemAdded)
					: undefined;
		});

	const setCloseOnClickOutside = (value: boolean) =>
		set((state) => {
			state.closeOnClickOutside = value;
		});

	const setItemAdded = (value: boolean) =>
		set((state) => {
			state.itemAdded = value;
		});

	const clearCart = () =>
		set((state) => {
			(state.cartItems = []),
				(state.subtotal = 0),
				(state.itemAdded = false);
		});

	const getCartItem = useCallback(
		(props: {
			type: string;
			productId: number;
			variationId?: number;
			bundleItems?: BundleItem[];
		}): CartItem | null => {
			const { type, productId, variationId, bundleItems } = props;

			if (variationId !== undefined) {
				return (
					cartItems?.find(
						(item) =>
							item.type === type &&
							item.productId === productId &&
							item.variationId === variationId &&
							areBundleItemsEqual(item.bundleItems, bundleItems)
					) || null
				);
			} else {
				return (
					cartItems?.find(
						(item) =>
							item.type === type &&
							item.productId === productId &&
							item.variationId === undefined &&
							areBundleItemsEqual(item.bundleItems, bundleItems)
					) || null
				);
			}
		},
		[cartItems]
	);

	return {
		updateCart,
		setCloseOnClickOutside,
		setItemAdded,
		clearCart,
		getCartItem,
	};
};
