import { createStyles, em, rem, getStylesRef } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		position: "relative",
		[theme.fn.largerThan(em(1024))]: {
			display: "none",
		},
	},

	drawer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},

	sectionTop: {
		height: `var(--header-height, ${rem(72)})`,
	},

	nav: {
		flexGrow: 1,
		overflowY: "hidden",
		// paddingLeft: theme.spacing.md,
		// paddingRight: theme.spacing.md,
		overflowX: "visible",
		boxSizing: "border-box",
	},

	ul: {
		display: "block",
		listStyle: "none",
		paddingLeft: 0,
	},

	li: {
		marginBottom: theme.spacing.xs,
		borderRadius: theme.radius.sm,
		marginLeft: theme.spacing.md,
		marginRight: theme.spacing.md,
		transition: `background-color 0.24s ${theme.transitionTimingFunction}`,
		ref: getStylesRef("li"),

		"&:hover": {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.fn.lighten(theme.colors.dark[7], 0.024)
					: theme.fn.darken(theme.colors.gray[0], 0.024),

			[`.${getStylesRef("text")}`]: {
				color:
					theme.colorScheme === "dark"
						? theme.colors.gray[0]
						: theme.colors.dark[7],
			},
		},

		"&:active": {
			...theme.activeStyles,
		},

		[`&:has(.${getStylesRef("active")})`]: {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.fn.lighten(theme.colors.dark[7], 0.024)
					: theme.fn.darken(theme.colors.gray[0], 0.024),
		},
	},

	liSubLink: {
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-between",
		alignItems: "center",
	},

	chevron: {
		display: "block",
		height: rem(18),
		marginRight: rem(12),
		transition: `transform 0.24s ${theme.transitionTimingFunction}`,
		color:
			theme.colorScheme === "dark"
				? theme.fn.darken(theme.colors.gray[0], 0.2)
				: theme.fn.lighten(theme.colors.dark[7], 0.2),
	},

	chevronActive: {
		transform: `rotate(90deg)`,
	},

	link: {
		...theme.fn.focusStyles(),
	},

	text: {
		padding: theme.spacing.xs,
		userSelect: "none",
		color:
			theme.colorScheme === "dark"
				? theme.fn.darken(theme.colors.gray[0], 0.2)
				: theme.fn.lighten(theme.colors.dark[7], 0.2),
		ref: getStylesRef("text"),
	},

	textSubLink: {
		paddingLeft: rem(28),
	},

	active: {
		ref: getStylesRef("active"),

		[`.${getStylesRef("text")}`]: {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.dark[7],
		},
	},

	footer: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[7]
				: theme.colors.gray[0],
		borderTop: `1px solid ${
			theme.colorScheme === "dark"
				? theme.colors.dark[5]
				: theme.colors.gray[2]
		}`,

		width: "100%",
		bottom: 0,
		left: 0,

		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		paddingTop: theme.spacing.sm,
		paddingBottom: theme.spacing.sm,
	},
}));
