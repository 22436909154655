import { CSSObject, rem, em } from "@mantine/core";

export const globalStyles = (theme): CSSObject => ({
	"@font-face": {
		fontFamily: "Zaychik",
		fontDisplay: "swap",
		fontStyle: "normal",
		fontWeight: "normal",
		src: `
			url('/fonts/Zaychik.woff2') format('woff2'),
			url('/fonts/Zaychik.woff') format('woff'),
			url('/fonts/Zaychik.ttf') format('truetype')
		`,
	},

	// "html, body": {
	// 	overflowX: "hidden",
	// 	width: "100%",
	// },

	html: {
		width: "100%",
	},
	body: {
		width: "100%",
		// height: "100vh",
		overflowY: "scroll",
		overflowX: "hidden",
	},

	p: {
		marginBlockStart: theme.spacing.sm,
		marginBlockEnd: theme.spacing.sm,
		lineHeight: 1.55,
	},

	"a, a:visited": {
		color: "unset",
		textDecoration: "none",
	},

	"a:visited": {
		color: "unset",
		textDecoration: "none",
	},

	"*::selection": {
		background: theme.colors.brandPrimary[7],
		color: theme.white,
	},

	// "*": {
	// 	transition: `background-color 0.42s ${theme.transitionTimingFunction}`,
	// },

	":root": {
		"--header-height": rem(64),
		"--section-margin": rem(72),
		"--section-margin-xs": rem(28),
		"--section-margin-sm": rem(34),
		"--safe-area": rem(56),

		[theme.fn.largerThan("xs")]: {
			"--header-height": rem(72),
		},

		[theme.fn.largerThan("sm")]: {},

		[theme.fn.largerThan("md")]: {
			"--section-margin": rem(96),
			"--section-margin-xs": rem(36),
			"--section-margin-sm": rem(48),
		},

		[theme.fn.largerThan("lg")]: {
			"--section-margin": rem(124),
			"--section-margin-xs": rem(42),
			"--section-margin-sm": rem(72),
		},
	},
});
