import { Box, Group, MediaQuery } from "@mantine/core";
import React from "react";
import { useStyles } from "./HeaderIcons.styles";
import ThemeSwitcher from "./ThemeSwitcher";
import Cart from "./Cart";
import Account from "./Account";

const HeaderIcons = () => {
	const { classes } = useStyles();

	return (
		<Box className={classes.root}>
			<MediaQuery smallerThan={1024} styles={{ display: "none" }}>
				<ThemeSwitcher />
			</MediaQuery>
			{/* <Account /> */}
			<Cart />
		</Box>
	);
};

export default HeaderIcons;
