import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const useGetOptions = () => {
	const { acfOptions } = useStaticQuery(graphql`
		query {
			acfOptions {
				acf {
					socialMedia {
						instagram
						telegram
						vk
						youtube
						whatsapp
					}
					companyName
					email
					phone
					seo {
						description
						image
						siteName
						siteUrl
					}
					workingHours
				}
			}
		}
	`);
	return acfOptions.acf;
};

export default useGetOptions;
