import { attributeKeys } from "@constants/attributeKeys";
import type { FormattedAttributes } from "@hooks/useFormatAttributes";
import { useState, useMemo } from "react";
import { ProductVariation, ProductBase } from "src/types/product.types";

interface UseProductVariationProps {
	type: string; //will equal "bundle" if its a bundle. not sure if you need it
	variations: ProductVariation[];
	attributes: FormattedAttributes;
	bundledProducts: ProductBase[];
	overrideSelectedAttributes?: { [key: string]: string };
	overrideSelectedBundleAttributes?: {
		[key: ProductBase["wcProductId"]]: { [key: string]: string };
	};
}

interface UseProductVariationReturn {
	selectedAttributes: { [key: string]: string };
	selectedBundleAttributes: {
		[key: ProductBase["wcProductId"]]: { [key: string]: string };
	};

	handleSetAttributes: (attributes: { [key: string]: string }) => void;
	handleSetBundleAttributes: (
		attributes: { [key: string]: string },
		wcProductId: ProductBase["wcProductId"]
	) => void;

	currentVariation: ProductVariation;
	currentBundleVariations: {
		[key: ProductBase["wcProductId"]]: ProductVariation;
	};
}
export const useProductVariations = ({
	type,
	variations,
	attributes,
	bundledProducts,
	overrideSelectedAttributes,
	overrideSelectedBundleAttributes,
}: UseProductVariationProps): UseProductVariationReturn => {
	const defaultAttributes = attributes.defaultAttributes;
	const defaultBundleAttributes = attributes.defaultBundleAttributes;

	const [selectedAttributes, setSelectedAttributes] = useState<{
		[key: string]: string;
	}>(overrideSelectedAttributes || defaultAttributes);

	const [selectedBundleAttributes, setSelectedBundleAttributes] = useState<{
		[key: ProductBase["wcProductId"]]: { [key: string]: string };
	}>(overrideSelectedBundleAttributes || defaultBundleAttributes);

	const currentVariation = useMemo(() => {
		const matchingVariation = variations.find((variation) =>
			variation.attributes.every((variationAttr) => {
				const key = attributeKeys[variationAttr.id] || variationAttr.name;
				return selectedAttributes[key] === variationAttr.option;
			})
		);

		return matchingVariation || null;
	}, [variations, selectedAttributes]);

	const currentBundleVariations = useMemo(() => {
		const bundleVariations: {
			[key: ProductBase["wcProductId"]]: ProductVariation;
		} = {};

		Object.keys(attributes.bundle).forEach((wcProductId) => {
			const wcProductIdNum = Number(wcProductId);

			if (!isNaN(wcProductIdNum)) {
				const bundleProduct = bundledProducts.find(
					(product) => product.wcProductId === wcProductIdNum
				);

				if (bundleProduct) {
					const bundleProductVariations = bundleProduct.variations;

					const selectedAttributesForBundle = {
						...selectedBundleAttributes[wcProductId],
						...selectedAttributes,
					};

					const matchingBundleVariation = bundleProductVariations.find(
						(variation) =>
							variation.attributes.every((variationAttr) => {
								const key =
									attributeKeys[variationAttr.id] ||
									variationAttr.name;
								return (
									selectedAttributesForBundle[key] ===
									variationAttr.option
								);
							})
					);

					bundleVariations[wcProductIdNum] =
						matchingBundleVariation || null;
				}
			}
		});

		return bundleVariations;
	}, [
		bundledProducts,
		selectedAttributes,
		selectedBundleAttributes,
		attributes.bundle,
	]);

	const handleSetAttributes = (updatedAttribute: {
		[key: string]: string;
	}) => {
		setSelectedAttributes({
			...selectedAttributes,
			...updatedAttribute,
		});
	};

	const handleSetBundleAttributes = (
		updatedAttribute: { [key: string]: string },
		wcProductId: ProductBase["wcProductId"]
	) => {
		setSelectedBundleAttributes((prevBundleAttributes) => {
			return {
				...prevBundleAttributes,
				[wcProductId]: {
					...prevBundleAttributes[wcProductId],
					...updatedAttribute,
				},
			};
		});
	};

	return {
		selectedAttributes,
		selectedBundleAttributes,
		currentVariation,
		currentBundleVariations,
		handleSetAttributes,
		handleSetBundleAttributes,
	};
};
