import { createStyles, em, getStylesRef } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		listStyle: "none",
		display: "flex",
		alignItems: "center",
		flexWrap: "nowrap",
		// height: "100%",
		// margin: 0,
		paddingInlineStart: 0,
		marginBlockStart: 0,
		marginBlockEnd: 0,

		[theme.fn.smallerThan(em(1024))]: {
			display: "none",
		},
	},

	hoverCard: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[7]
				: theme.colors.gray[0],
	},

	link: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		textDecoration: "none",
		// color: theme.colorScheme === "dark" ? "red" : theme.colors.dark[7],
		fontWeight: 400,

		[`&:hover .${getStylesRef("chevron")}`]: {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.dark[7],
		},

		[`&:hover .${getStylesRef("linkStyles")}`]: {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.dark[7],
		},
	},

	linkStyles: {
		color:
			theme.colorScheme === "dark"
				? theme.fn.darken(theme.colors.gray[0], 0.11)
				: theme.fn.lighten(theme.colors.dark[7], 0.2),
		...theme.fn.hover({
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.dark[7],
		}),
		lineHeight: 1,
		ref: getStylesRef("linkStyles"),
		transition: `color 0.24s ${theme.transitionTimingFunction}`,
	},

	chevron: {
		color:
			theme.colorScheme === "dark"
				? theme.fn.darken(theme.colors.gray[0], 0.11)
				: theme.fn.lighten(theme.colors.dark[7], 0.2),
		ref: getStylesRef("chevron"),
	},

	li: {
		height: "100%",
	},

	menuLink: {
		// width: "100%",
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		borderRadius: theme.radius.md,

		...theme.fn.hover({
			transition: `background-color 0.42s ${theme.transitionTimingFunction}`,
			// backgroundColor: theme.colors.brandTertiary[0],
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[6]
					: theme.colors.gray[1],
		}),

		[`&:hover .${getStylesRef("linkStyles")}`]: {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.dark[7],
		},

		"&:active": theme.activeStyles,
	},
}));
