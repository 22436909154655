import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: rem(56),
		borderTop:
			theme.colorScheme === "dark"
				? `${rem(1)} solid ${theme.colors.dark[6]}`
				: `${rem(1)} solid ${theme.colors.gray[2]}`,
	},

	logoWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		[theme.fn.largerThan("xs")]: {
			justifyContent: "flex-start",
		},
	},

	logo: {
		height: rem(27),
		marginBottom: theme.spacing.xs,
		fill:
			theme.colorScheme === "dark"
				? theme.colors.gray[0]
				: theme.colors.dark[7],
	},

	socialWrapper: {
		display: "flex",
		justifyContent: "center",
		gap: theme.spacing.xs,
		marginTop: rem(4),

		[theme.fn.largerThan("xs")]: {
			justifyContent: "flex-start",
		},
	},

	socialIcon: {
		transition: `color 0.24s ${theme.transitionTimingFunction}`,
		color: theme.colors.gray[7],

		"&:hover": {
			color: theme.colors.gray[8],
		},
		// color: theme.colors.brandPrimary[5],

		// "&:hover": {
		// 	color: theme.colors.brandPrimary[7],
		// },
	},

	col: {},

	colTitle: {
		textAlign: "center",

		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[0]
				: theme.colors.dark[7],

		[theme.fn.largerThan("xs")]: {
			textAlign: "initial",
			marginBottom: rem(4),
		},
	},

	colText: {
		display: "flex",
		alignItems: "center",
		lineHeight: 1.5,
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[7]
				: theme.colors.gray[6],
	},

	ul: {
		listStyle: "none",
		paddingLeft: 0,
		marginTop: 0,
		marginBottom: 0,

		display: "flex",
		flexDirection: "column",
		alignItems: "center",

		[theme.fn.largerThan("xs")]: {
			alignItems: "flex-start",
		},
	},

	li: {
		lineHeight: 1.5,
	},

	link: {
		display: "inline-block",
	},

	linkText: {
		transition: `color 0.24s ${theme.transitionTimingFunction}`,
		display: "flex",
		alignItems: "center",
		lineHeight: 1.5,
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[7]
				: theme.colors.gray[6],

		...theme.fn.hover({
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[4]
					: theme.colors.gray[9],
		}),
	},

	linkIcon: {
		// display: "block",
		marginRight: rem(5),
	},

	contactWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",

		[theme.fn.largerThan("xs")]: {
			alignItems: "flex-start",
		},
	},

	bot: {
		paddingTop: rem(32),
		paddingBottom: theme.spacing.lg,

		[theme.fn.largerThan("xs")]: {
			paddingTop: rem(56),
			paddingBottom: theme.spacing.xl,
		},
	},

	leftText: {
		textAlign: "center",
		fontSize: rem(11),
		textTransform: "uppercase",
		fontWeight: 500,
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[7]
				: theme.colors.gray[6],

		[theme.fn.largerThan("xs")]: {
			textAlign: "left",
			fontSize: rem(12),
		},
	},

	rightText: {
		textAlign: "center",
		fontSize: rem(11),
		textTransform: "uppercase",
		fontWeight: 500,
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[7]
				: theme.colors.gray[6],

		[theme.fn.largerThan("xs")]: {
			textAlign: "right",
			fontSize: rem(12),
		},
	},

	copyLink: {
		color: "unset",
		fontSize: "unset",
		// textDecoration: "underline",

		"&:hover": {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[4]
					: theme.colors.gray[9],
		},
	},
}));
