import React from "react";
import { Box, BoxProps, Text } from "@mantine/core";
import { useCartStore } from "src/store/cartStore";
import { graphql, useStaticQuery } from "gatsby";
import { formatGatsbyEdges } from "@helpers/formatGatsbyEdges";
import { useStyles } from "./CartItems.style";
import SingleCartItem from "./SingleCartItem";
import { shallow } from "zustand/shallow";
import { v4 as uuid } from "uuid";

export interface CartItemsProps extends BoxProps {
	size?: "xs" | "md";
}

const CartItems = ({ size = "md", className, ...rest }: CartItemsProps) => {
	const cartItems = useCartStore((state) => state.cartItems, shallow);
	const { allProduct } = useStaticQuery(query);
	const products = formatGatsbyEdges(allProduct.edges);
	const { classes, theme, cx } = useStyles({ size });

	return (
		<>
			<Box className={cx(className, classes.root)} {...rest}>
				{cartItems.length > 0 ? (
					cartItems.map((item) => (
						<SingleCartItem
							key={uuid()}
							products={products}
							item={item}
							size={size}
						/>
					))
				) : (
					<>
						<Text className={classes.emptyCart}>
							Ваша корзина пуста...
						</Text>
					</>
				)}
			</Box>
		</>
	);
};

const query = graphql`
	query {
		allProduct {
			edges {
				node {
					...ProductFieldsFragment
				}
			}
		}
	}
`;

export default CartItems;
