import React, { useEffect } from "react";
import { useStyles } from "./MobileMenu.styles";
import { useDisclosure } from "@mantine/hooks";
import { globalHistory } from "@reach/router";
import {
	ActionIcon,
	Box,
	Burger,
	Collapse,
	Drawer,
	ScrollArea,
	Text,
	UnstyledButton,
	rem,
} from "@mantine/core";
import { headerLinks } from "../header-links";
import { Link } from "gatsby";
import {
	IconBrandInstagram,
	IconBrandTelegram,
	IconChevronRight,
} from "@tabler/icons-react";
import ThemeSwitcher from "../HeaderIcons/ThemeSwitcher";
import useGetOptions from "@hooks/useGetOptions";

const slide = {
	in: { transform: "translateX(0%)" },
	out: { transform: "translateX(-100%)" },
	common: { transformOrigin: "left" },
	transitionProperty: "transform",
};

const MenuLi = ({ item }) => {
	const { classes, cx } = useStyles();
	const [opened, { toggle }] = useDisclosure();

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			toggle();
		}
	};

	const handleNavigateEvent = (event) => {
		event.currentTarget.querySelector("a").click();
	};

	const handleNavigateKeyDown = (event) => {
		if (event.key === "Enter") {
			handleNavigateEvent(event);
		}
	};

	const link = (
		<UnstyledButton
			component="li"
			className={classes.li}
			tabIndex={0}
			onClick={handleNavigateEvent}
			onKeyDown={handleNavigateKeyDown}
		>
			<Link
				to={item.link}
				className={classes.link}
				activeClassName={classes.active}
				tabIndex={-1}
			>
				<Text className={classes.text}>{item.label}</Text>
			</Link>
		</UnstyledButton>
	);

	const menu = (
		<>
			<UnstyledButton
				component="li"
				className={cx(classes.li, classes.liSubLink)}
				onClick={toggle}
				tabIndex={0}
				onKeyDown={handleKeyDown}
			>
				<Text className={classes.text}>{item.label}</Text>
				<IconChevronRight
					className={cx(
						classes.chevron,
						opened ? classes.chevronActive : null
					)}
				/>
			</UnstyledButton>
			<Collapse in={opened}>
				<Box component="li">
					<Box component="ul" className={classes.ul}>
						{item?.menuItems?.map((subLink) => (
							<UnstyledButton
								component="li"
								className={classes.li}
								key={subLink.name}
								tabIndex={0}
								onClick={handleNavigateEvent}
								onKeyDown={handleNavigateKeyDown}
							>
								<Link
									to={subLink.link}
									className={classes.link}
									activeClassName={classes.active}
									tabIndex={-1}
								>
									<Text
										className={cx(classes.text, classes.textSubLink)}
									>
										{subLink.label}
									</Text>
								</Link>
							</UnstyledButton>
						))}
					</Box>
				</Box>
			</Collapse>
		</>
	);

	return item.type === "link" ? link : menu;
};

const MobileMenu = () => {
	const { classes } = useStyles();
	const [opened, { toggle, close }] = useDisclosure();
	const label = opened ? "закрыть меню" : "открыть меню";
	const { socialMedia } = useGetOptions();

	useEffect(() => {
		return globalHistory.listen(({ action }) => {
			if (action === "PUSH") close();
		});
	}, []);

	return (
		<>
			<Burger
				opened={opened}
				onClick={toggle}
				aria-label={label}
				className={classes.root}
				mr="xs"
			/>
			<Drawer
				opened={opened}
				onClose={close}
				withCloseButton={false}
				padding={0}
				classNames={{ body: classes.drawer }}
				transitionProps={{ transition: slide, duration: 342 }}
				overlayProps={{ opacity: 0.36, blur: 0 }}
				zIndex={300}
			>
				<Box className={classes.sectionTop}></Box>
				<Box component="nav" className={classes.nav}>
					<ScrollArea type="never" h="100%">
						<Box component="ul" className={classes.ul}>
							{headerLinks.map((item) => (
								<MenuLi item={item} key={item.name} />
							))}
						</Box>
					</ScrollArea>
				</Box>
				<Box className={classes.footer}>
					{socialMedia.instagram ? (
						<a href={socialMedia.instagram} target="_blank">
							<ActionIcon
								variant="transparent"
								sx={{ width: rem(54), height: rem(38) }}
							>
								<IconBrandInstagram size={rem(28)} strokeWidth={1.42} />
							</ActionIcon>
						</a>
					) : null}

					{socialMedia.telegram ? (
						<a href={socialMedia?.telegram} target="_blank">
							<ActionIcon
								variant="transparent"
								sx={{ width: rem(54), height: rem(38) }}
							>
								<IconBrandTelegram size={rem(28)} strokeWidth={1.42} />
							</ActionIcon>
						</a>
					) : null}

					<ThemeSwitcher />
				</Box>
			</Drawer>
		</>
	);
};

export default MobileMenu;
