import {
	Box,
	Group,
	Popover,
	SimpleGrid,
	Text,
	UnstyledButton,
	rem,
	clsx,
} from "@mantine/core";
import React from "react";
import { headerLinks } from "../header-links";
import { useStyles } from "./DesktopLinks.styles";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { Link } from "gatsby";

const DesktopLinkPopover = ({ item, links }) => {
	const [opened, { close, open }] = useDisclosure(false);
	const { classes } = useStyles();

	let closeTimeout = null;

	const handleMouseLeave = () => {
		closeTimeout = setTimeout(() => {
			close();
		}, 172);
	};

	const handleMouseEnter = () => {
		if (closeTimeout) {
			clearTimeout(closeTimeout);
			closeTimeout = null;
		}
		open();
	};

	return (
		<Popover
			id={item.name}
			width={600}
			position="bottom"
			radius="sm"
			shadow="xs"
			withinPortal
			// zIndex={100}
			classNames={{ dropdown: classes.hoverCard }}
			opened={opened}
		>
			<Popover.Target>
				<UnstyledButton
					// component={Link}
					// to={item?.link}
					className={classes.link}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<Text
						variant="subtitle"
						className={classes.linkStyles}
						mr={rem(2)}
					>
						{item.label}
					</Text>
					<IconChevronDown
						size={rem(14)}
						strokeWidth={2}
						className={classes.chevron}
					/>
				</UnstyledButton>
			</Popover.Target>
			<Popover.Dropdown
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<SimpleGrid cols={2} spacing={0} onClick={close}>
					{links}
				</SimpleGrid>
			</Popover.Dropdown>
		</Popover>
	);
};

const DesktopLink = ({ item }) => {
	const { classes } = useStyles();
	return (
		<Box
			component={Link}
			to={item.link}
			className={clsx(classes.link, classes.linkStyles)}
			// sx={{ height: "100%" }}
		>
			<Text variant="subtitle" sx={{ lineHeight: 1 }}>
				{item.label}
			</Text>
		</Box>
	);
};

const DesktopMenu = ({ item }) => {
	const { classes, theme } = useStyles();

	const links = item.menuItems.map((item) => (
		<UnstyledButton
			component={Link}
			to={item.link}
			className={classes.menuLink}
			key={item.label}
		>
			<Group noWrap align="flex-start">
				<item.icon
					size={rem(22)}
					color={theme.colors.brandPrimary[6]}
					strokeWidth={1.72}
				/>
				<Box sx={{ flex: 1 }}>
					<Text
						variant="subtitle"
						size="sm"
						className={classes.linkStyles}
						mb={rem(4)}
					>
						{item.label}
					</Text>
					<Text size="xs" color="dimmed">
						{item.description}
					</Text>
				</Box>
			</Group>
		</UnstyledButton>
	));

	return <>{item ? <DesktopLinkPopover item={item} links={links} /> : null}</>;
};

const DesktopLinks = () => {
	const { classes } = useStyles();
	return (
		<Box component="ul" className={classes.root}>
			{headerLinks.map((item) => (
				<Box component="li" key={item.name} className={classes.li}>
					{
						{
							link: <DesktopLink item={item} />,
							menu: <DesktopMenu item={item} />,
						}[item.type]
					}
				</Box>
			))}
		</Box>
	);
};

export default DesktopLinks;
