import { createStyles, rem } from "@mantine/core";
import { CartItemsProps } from "./CartItems";

export const useStyles = createStyles((theme, { size }: CartItemsProps) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing.xs,
	},

	emptyCart: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingTop:
			{
				xs: rem(24),
				md: rem(72),
			}[size] || rem(24),
		paddingBottom:
			{
				xs: rem(24),
				md: rem(72),
			}[size] || rem(24),
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[0],
		marginBottom:
			size === "xs"
				? theme.spacing.xs
				: size === "md"
				? theme.spacing.md
				: 0,
		marginTop:
			size === "xs"
				? theme.spacing.xs
				: size === "md"
				? theme.spacing.md
				: 0,
	},

	productCard: {
		position: "relative",
		display: "flex",
		gap: size === "xs" ? theme.spacing.xs : theme.spacing.md,
	},

	// productImage: {
	// 	width: { xs: rem(72), md: rem(96) }[size] || rem(72),

	// 	[theme.fn.largerThan("sm")]: {
	// 		width: { xs: rem(72), md: rem(124) }[size] || rem(72),
	// 	},
	// },

	productImageWrapper: {
		width: { xs: rem(72), md: rem(96) }[size] || rem(72),

		[theme.fn.largerThan("sm")]: {
			width: { xs: rem(72), md: rem(124) }[size] || rem(72),
		},
	},

	cardContentWrapper: {
		flex: 1,
		display: "flex",
		flexDirection: "column",

		[theme.fn.largerThan("sm")]: {
			flexDirection: size === "xs" ? undefined : "row",
		},
	},

	productName: {
		fontSize: size === "xs" ? rem(12) : rem(14),
		paddingRight: rem(25),

		[theme.fn.largerThan("sm")]: {
			paddingRight: size === "xs" ? rem(25) : undefined,
			fontSize: size === "xs" ? rem(12) : rem(16),
		},
	},

	cardContent: {
		flex: 1,
		alignSelf: "flex-start",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		gap: size === "xs" ? rem(2) : rem(4),

		[theme.fn.largerThan("sm")]: {
			alignSelf: size === "xs" ? undefined : "center",
			alignItems: "flex-start",
			gap: size === "xs" ? rem(2) : rem(5),
		},
	},

	close: {
		order: -1,
		alignSelf: "flex-end",

		marginBottom: rem(-22),

		[theme.fn.largerThan("sm")]: {
			order: size === "xs" ? undefined : "initial",
			alignSelf: size === "xs" ? undefined : "flex-start",
			marginLeft: rem(42),
			marginBottom: size === "xs" ? rem(-22) : 0,
		},
	},

	qtyWrapper: {
		display: "flex",
		alignItems: "center",
		alignSelf: "flex-end",
		justifySelf: "flex-end",
		marginTop: rem(-22),

		[theme.fn.largerThan("sm")]: {
			marginTop: size === "xs" ? rem(-22) : 0,
			alignSelf: size === "xs" ? undefined : "center",
		},
	},

	quantity: {
		lineHeight: 1,
		marginLeft: rem(4),
		marginRight: rem(4),
		fontSize: size === "xs" ? rem(11) : rem(12),

		[theme.fn.largerThan("sm")]: {
			marginLeft: size === "xs" ? undefined : rem(7),
			marginRight: size === "xs" ? undefined : rem(7),
			fontSize: size === "xs" ? rem(12) : rem(14),
		},
	},

	itemPrice: {
		fontSize: size === "xs" ? rem(12) : rem(14),
		fontWeight: 500,

		[theme.fn.largerThan("sm")]: {
			fontSize: size === "xs" ? rem(12) : rem(16),
			marginTop: size === "xs" ? undefined : rem(14),
		},
	},
}));
