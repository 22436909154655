import React from "react";
import { Box, RemoveScroll } from "@mantine/core";
import HeaderLogo from "./HeaderLogo";
import { useStyles } from "./Header.styles";
import { HeaderIcons } from "./HeaderIcons";
import DesktopLinks from "./DesktopMenu/DesktopLinks";
import { MobileMenu } from "./MobileMenu";

const Header = () => {
	const { classes } = useStyles();

	return (
		<>
			<Box component="header" className={classes.root}>
				<Box className={classes.outerWrapper}>
					<MobileMenu />
					<HeaderLogo />
				</Box>
				<Box className={classes.innerWrapper}>
					<DesktopLinks />
				</Box>
				<Box className={classes.outerWrapper}>
					<HeaderIcons />
				</Box>
				<Box className={RemoveScroll.classNames.fullWidth} />
			</Box>
			<Box className={classes.spacer} />
		</>
	);
};

export default Header;
