import {
	ColorScheme,
	ColorSchemeProvider,
	MantineProvider as _MantineProvider,
} from "@mantine/core";
import { useColorScheme, useHotkeys, useLocalStorage } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { theme } from "@styles/theme";
import React from "react";

const MantineProvider = ({ children }) => {
	const preferredColorScheme = useColorScheme();
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: "mantine-color-scheme",
		defaultValue: preferredColorScheme,
		getInitialValueInEffect: true,
	});
	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

	useHotkeys([["mod+J", () => toggleColorScheme()]]);

	return (
		<>
			<ColorSchemeProvider
				colorScheme={colorScheme}
				toggleColorScheme={toggleColorScheme}
			>
				<_MantineProvider
					withGlobalStyles
					withNormalizeCSS
					theme={{ ...theme, colorScheme }}
				>
					<Notifications autoClose={7000} />
					{children}
				</_MantineProvider>
			</ColorSchemeProvider>
		</>
	);
};

export default MantineProvider;
