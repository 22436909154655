import { createStyles, em, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		right: `var(--removed-body-scroll-bar-size, 0)`,
		zIndex: 250,
		position: "fixed",
		paddingTop: rem(7),
		paddingBottom: rem(7),
		borderBottom:
			theme.colorScheme === "dark"
				? `${rem(1)} solid ${theme.colors.dark[6]}`
				: `${rem(1)} solid ${theme.colors.gray[2]}`,
		width: "100vw",

		// backgroundColor:
		// 	theme.colorScheme === "dark"
		// 		? theme.colors.dark[5]
		// 		: theme.colors.gray[1],
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[5]
				: theme.colors.brandPrimary[0],
	},

	container: {
		position: "relative",
	},
	text: {
		// display: "flex",
		// alignItems: "center",
		paddingLeft: rem(28),
		paddingRight: rem(28),

		fontSize: rem(10),
		textTransform: "uppercase",
		fontWeight: 500,
		lineHeight: 1.2,
		// color:
		// 	theme.colorScheme === "dark"
		// 		? theme.colors.dark[7]
		// 		: theme.colors.gray[0],
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[3]
				: theme.colors.gray[7],

		[theme.fn.largerThan(em(360))]: {
			fontSize: rem(12),
		},
		a: {
			textDecoration: "underline",
		},
	},

	link: {
		fontSize: "unset",
		color: "unset",

		"&:hover": {
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.gray[8],
		},
	},

	close: {
		position: "absolute",
		right: 0,
		top: "50%",
		transform: "translate(0%, -50%)",
		// backgroundColor:
		// 	theme.colorScheme === "dark"
		// 		? theme.colors.dark[6]
		// 		: theme.colors.gray[3],
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.fn.darken(theme.colors.dark[5], 0)
				: theme.fn.darken(theme.colors.brandPrimary[0], 0),
		borderRadius: theme.radius.sm,
		padding: rem(3),
		color:
			theme.colorScheme === "dark"
				? theme.colors.gray[4]
				: theme.colors.gray[6],

		svg: {
			display: "block",
			strokeWidth: 1.8,
			width: rem(18),
			height: rem(18),
		},

		"&:hover": {
			transition: `background-color 0.24s ${theme.transitionTimingFunction}`,
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.fn.darken(theme.colors.dark[5], 0.09)
					: theme.fn.darken(theme.colors.brandPrimary[0], 0.02),
			color:
				theme.colorScheme === "dark"
					? theme.colors.gray[0]
					: theme.colors.gray[8],
		},
	},
}));
