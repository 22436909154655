import {
	IconBoxSeam,
	IconBuildingEstate,
	IconCreditCard,
	IconHeart,
	IconReceiptRefund,
	IconShirt,
	IconStar,
	IconTimelineEventText,
} from "@tabler/icons-react";

export const headerLinks = [
	{
		name: "shop",
		label: "Каталог",
		link: "/shop",
		type: "link",
	},
	{
		name: "about",
		label: "О нас",
		link: "/about",
		type: "menu",
		menuItems: [
			{
				name: "company",
				label: "Философия",
				icon: IconBuildingEstate,
				description: "Ради чего это все.",
				type: "menuLink",
				link: "/about/company",
			},
			{
				name: "process",
				label: "Производство",
				icon: IconTimelineEventText,
				description: "Как создается Loona.",
				type: "menuLink",
				link: "/about/process",
			},
			{
				name: "reviews",
				label: "Отзывы",
				icon: IconStar,
				description: "Что говорят о нас.",
				type: "menuLink",
				link: "/about/reviews",
			},
		],
	},
	{
		name: "savingCats",
		label: "Мяу",
		link: "/helping/cats",
		type: "link",
	},

	{
		name: "info",
		label: "Полезное",
		link: "/info",
		type: "menu",
		menuItems: [
			{
				name: "sizing",
				label: "Размеры",
				icon: IconShirt,
				description: "Подберем идеально сидящую одежду.",
				type: "menuLink",
				link: "/info/sizing",
			},
			// {
			// 	name: "care",
			// 	label: "Уход за одеждой",
			// 	icon: IconHeart,
			// 	description: "Лайфхаки, чтобы передать ее вашим внукам.",
			// 	type: "menuLink",
			// 	link: "/info/care",
			// },
			{
				name: "delivery",
				label: "Доставка",
				icon: IconBoxSeam,
				description: "Для вас бесплатно.",
				type: "menuLink",
				link: "/info/delivery",
			},
			{
				name: "payments",
				label: "Оплата",
				icon: IconCreditCard,
				description: "Картой, наличными и веточками.",
				type: "menuLink",
				link: "/info/payments",
			},
			{
				name: "returns",
				label: "Возврат",
				icon: IconReceiptRefund,
				description: "Конечно же, он есть.",
				type: "menuLink",
				link: "/info/returns",
			},
		],
	},
	{
		name: "contact",
		label: "Контакты",
		link: "/contact",
		type: "link",
	},
];
