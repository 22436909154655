import React from "react";
import { Box } from "@mantine/core";
import { EmailCTA } from "./EmailCTA";

const BottomCTA = () => {
	return (
		<Box component="section">
			<EmailCTA />
		</Box>
	);
};

export default BottomCTA;
