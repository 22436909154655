import {
	Anchor,
	Box,
	Container,
	RemoveScroll,
	Text,
	Transition,
	UnstyledButton,
	rem,
} from "@mantine/core";
import { Link } from "gatsby";
import React from "react";
import { useStyles } from "./Banner.styles";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowNarrowRight, IconBoxSeam, IconX } from "@tabler/icons-react";

const Banner = () => {
	const { classes, cx } = useStyles();
	const [opened, { close }] = useDisclosure(true);

	return (
		<>
			<Transition mounted={opened} transition="scale-y" duration={346}>
				{(styles) => (
					<Box className={classes.root} style={styles}>
						<Container className={cx(classes.container)}>
							<Text className={classes.text} align="center">
								{/* &nbsp;&nbsp; */}
								<Anchor
									color="dimmed"
									component={Link}
									to="/info/delivery/"
									className={classes.link}
									ml={rem(4)}
									inline
								>
									Бесплатная доставка по России!
									{/* Подробнее */}
								</Anchor>
							</Text>
							<UnstyledButton className={classes.close} onClick={close}>
								<IconX />
							</UnstyledButton>
						</Container>
					</Box>
				)}
			</Transition>
		</>
	);
};

export default Banner;
