import { useMemo } from "react";
import { useCartStore } from "src/store/cartStore";
import { ProductVariation } from "src/types/product.types";
import { shallow } from "zustand/shallow";

interface UseVariationImageIdsProps {
	productType: string;
	currentVariation: ProductVariation | null;
	selectedAttributes: { [key: string]: string };
	currentBundleVariations: { [key: number]: ProductVariation };
}

export const useVariationImageIds = ({
	productType,
	currentVariation,
	selectedAttributes,
	currentBundleVariations,
}: UseVariationImageIdsProps): number[] | undefined => {
	// const cartItems = useCartStore((state) => state.cartItems, shallow);

	const filterImageIds = useMemo(() => {
		const selectedIds: Set<number> = new Set();

		if (currentVariation && productType !== "bundle") {
			const variationImages: string[] | string | undefined =
				currentVariation?.meta_data.find(
					(metaData) => metaData.key === "rtwpvg_images"
				)?.value;

			if (variationImages) {
				if (Array.isArray(variationImages)) {
					variationImages.forEach((id) => selectedIds.add(Number(id)));
				} else {
					selectedIds.add(Number(variationImages));
				}
			}
		}

		if (productType === "bundle") {
			Object.values(currentBundleVariations).forEach((bundleVariation) => {
				const variationImages: string[] | string | undefined =
					bundleVariation?.meta_data.find(
						(metaData) => metaData.key === "rtwpvg_images"
					)?.value;

				if (variationImages) {
					if (Array.isArray(variationImages)) {
						variationImages.forEach((id) => selectedIds.add(Number(id)));
					} else {
						selectedIds.add(Number(variationImages));
					}
				}
			});
		}

		return selectedIds.size > 0 ? Array.from(selectedIds) : undefined;
	}, [
		// cartItems,
		currentVariation,
		productType,
		selectedAttributes,
		currentBundleVariations,
	]);

	return filterImageIds;
};
