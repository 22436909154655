import React, { useState } from "react";
import {
	Box,
	TextInput,
	Button,
	Text,
	Divider,
	Title,
	Container,
	Anchor,
	LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useStyles } from "./EmailCta.styles";
import Marquee from "react-fast-marquee";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
	IconAlertCircle,
	// IconBrandInstagram,
	// IconBrandTelegram,
	// IconBrandVk,
	// IconBrandYoutube,
	// IconCheck,
	IconMailCheck,
	IconSend,
} from "@tabler/icons-react";
import { StaticImage } from "gatsby-plugin-image";
import { api } from "@helpers/axios";
import { notifications } from "@mantine/notifications";

const EmailCta = () => {
	const { classes } = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			email: "",
		},
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Неверный email"),
		},
	});

	const handleSubmit = async (values) => {
		setIsLoading(true);
		try {
			const response = await api.post("/mailing/subscribe", values);
			console.log(response);
			notifications.show({
				title: "Спасибо, что подписались!",
				message: response.data,
				withBorder: true,
				color: "brandPrimary",
				icon: <IconMailCheck strokeWidth={1.5} size="1.2rem" />,
			});
			setIsLoading(false);
			form.reset();
		} catch (error) {
			console.log(error);
			notifications.show({
				title: "Упс...",
				message:
					error?.response?.data ||
					error?.message ||
					"No error message provided.",
				withBorder: true,
				color: "brandSecondary",
				icon: <IconAlertCircle strokeWidth={1.5} size="1.2rem" />,
			});
			setIsLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<LoadingOverlay visible={isLoading} />
			<Divider />
			<Box py="xs">
				<Marquee autoFill speed={36}>
					<Text className={classes.marqueeText} color="dimmed">
						Даже кошки завидуют твоему
						обаянию&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Ты настоящий кошмар для скуки&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Кошки уже просят у тебя уроки по
						комфорту&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Люди вокруг мурлычут от твоего
						стиля&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Твой стиль сегодня – шик-мяу&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Ты просто котиковое наслаждение для
						глаз&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Ты совершаешь котолюцию в мире
						моды&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Даже у Лагерфельда нет такой кошки как
						ты&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
					<Text className={classes.marqueeText} color="dimmed">
						Ты прямое попадание в кошачье
						сердце&nbsp;&nbsp;&#8212;&nbsp;&nbsp;
					</Text>
				</Marquee>
			</Box>
			<Divider />
			<Box className={classes.bgRoot}>
				<Container size="lg">
					<Title order={2} className={classes.title} align="center">
						Подпишись на нашу рассылку
					</Title>
					<Box className={classes.wrapper}>
						<Box
							className={classes.imgWrapper}
							style={{ position: "relative", zIndex: 1 }}
						>
							<StaticImage
								src={
									"../../../assets/images/cats-in-hoodies-line-bg-removed-2.png"
								}
								alt="image"
								className={classes.image}
								draggable={false}
								style={{ opacity: 0.78 }}
							/>
						</Box>
						<Box
							component="form"
							className={classes.form}
							onSubmit={form.onSubmit((values) => handleSubmit(values))}
						>
							<Text className={classes.formTitle} mb="sm">
								Акции, новости и разные приятности.
							</Text>
							<Box className={classes.emailWrapper} mb="md">
								<TextInput
									placeholder="Email"
									id="cta-email"
									mr="md"
									style={{ flex: 1 }}
									{...form.getInputProps("email")}
									classNames={{
										root: classes.inputRoot,
										wrapper: classes.inputWrapper,
										error: classes.inputError,
									}}
								/>
								<Button
									rightIcon={<IconSend size={14} strokeWidth={1.5} />}
									color="brandPrimary"
									type="submit"
								>
									Подписаться
								</Button>
							</Box>
							<Text
								className={classes.formPolicy}
								color="dimmed"
								size="xs"
							>
								Нажимая на кнопку "Подписаться", вы соглашаетесь с
								условиями{" "}
								<Anchor
									component={Link}
									to="/privacy-policy"
									color="unset"
									size="unset"
								>
									Политики конфиденциальности
								</Anchor>
								{", "}а также с тем, что вы котик.
							</Text>
						</Box>
					</Box>
					{/* <Box style={{ position: "relative", zIndex: 1 }}>
						<StaticImage
							src={
								"../../../assets/images/cats-in-hoodies-line-bg-removed.png"
							}
							alt="image"
							// className={classes.image}
							imgClassName={classes.image}
							draggable={false}
							style={{ opacity: 0.72 }}
						/>
					</Box> */}
				</Container>
			</Box>

			{/* <Box className={classes.marqueeWrapper}>
				<Text className={classes.marquee}>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit.
						Perferendis molestiae omnis ut placeat hic ullam quaerat
						corrupti, amet possimus magnam accusantium incidunt minus
						velit nulla!
					</p>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit.
						Perferendis molestiae omnis ut placeat hic ullam quaerat
						corrupti, amet possimus magnam accusantium incidunt minus
						velit nulla!
					</p>
				</Text>
			</Box> */}
		</Box>
	);
};

export default EmailCta;
