import { MantineThemeOverride, rem } from "@mantine/core";
import { colors } from "./colors";
import { globalStyles } from "./globalStyles";
import { defaults } from "./defaults";

export const theme: MantineThemeOverride = {
	globalStyles: globalStyles,
	components: defaults,
	fontFamily: "Montserrat, sans-serif",
	// black: "#181716",
	// white: "#fbfbfb",
	black: "#161616",
	// white: "#fcfcfc",
	white: "#ffffff",
	defaultRadius: "xs",
	transitionTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
	headings: {
		fontFamily: "Montserrat, sans-serif",
		fontWeight: 500,
	},
	colors,
	focusRingStyles: {
		resetStyles: () => ({ outline: "none" }),

		styles: (theme) => ({
			outline: `${rem(2)} solid ${theme.colors.brandPrimary[6]}`,
		}),

		inputStyles: (theme) => ({
			outline: "none",
			borderColor: theme.colors.brandPrimary[6],
		}),
	},
	other: {},
};
