import React, { useEffect, useState } from "react";
import MantineProvider from "./MantineProvider";
import { Header } from "./Header";
import Page from "./Page";
import { Footer } from "./Footer";
import { BottomCTA } from "@modules/BottomCTA";
import { Banner } from "./Banner";
import { Box, rem, Anchor, Button, Text, Group } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { Link } from "gatsby";
import { IconCookie } from "@tabler/icons-react";

const Layout = ({ children }) => {
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorage({
		key: "loona-cookie-accepted",
		defaultValue: false,
	});

	const handleAcceptCookies = () => {
		setHasAcceptedCookies(true);
		notifications.hide("loona-cookie");
	};

	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false);
		}
	}, [isInitialLoad]);

	useEffect(() => {
		if (!hasAcceptedCookies && !isInitialLoad) {
			notifications.show({
				id: "loona-cookie",
				autoClose: false,
				withCloseButton: false,
				radius: "sm",
				withBorder: true,
				color: "brandPrimary",
				icon: <IconCookie size="1.2rem" strokeWidth={1.5} />,
				p: "xl",
				message: (
					<Group noWrap>
						<Text>
							Мы используем{" "}
							<Anchor
								component={Link}
								color="brandPrimary"
								to="/privacy-policy"
							>
								cookies
							</Anchor>
							{", "}
							чтобы сайт становился еще лучше.
						</Text>

						<Button
							compact
							onClick={handleAcceptCookies}
							color="brandPrimary"
							variant="light"
							sx={{ width: rem(90) }}
						>
							Окей
						</Button>
					</Group>
				),
			});
		}
	}, [isInitialLoad]);

	return (
		<>
			<MantineProvider>
				<Header />
				<Banner />

				<Box
					sx={{
						position: "relative",
						// height: `calc(100vh - var(--header-height, ${rem(72)}))`,
						overflow: "hidden",
						width: "100%",
						paddingRight: `var(--removed-body-scroll-bar-size, 0)`,
					}}
				>
					{/* <ScrollArea.Autosize
					mah={`calc(100vh - var(--header-height, ${rem(72)}))`}
					w="100vw"

					// maw={"100%"}
				> */}
					<Page>{children}</Page>
					<BottomCTA />
					<Footer />
					{/* </ScrollArea.Autosize> */}
				</Box>
			</MantineProvider>
		</>
	);
};

export default Layout;
