import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/cyrillic-400.css";
import "@fontsource/montserrat/cyrillic-500.css";
import "@fontsource/montserrat/cyrillic-600.css";
import "@fontsource/bad-script";

export const onServiceWorkerUpdateReady = () =>
	typeof window !== "undefined" && window.location.reload();

export const onRouteUpdate = function ({ location }, pluginOptions) {
	// Don't track while developing.
	if (
		process.env.NODE_ENV === `production` &&
		// @ts-ignore
		typeof window.ym !== `undefined` &&
		location
	) {
		const { pathname, search, hash } = location;
		// @ts-ignore
		window.ym(pluginOptions.trackingId, "hit", pathname + search + hash);
	}
};
