exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-company-tsx": () => import("./../../../src/pages/about/company.tsx" /* webpackChunkName: "component---src-pages-about-company-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-process-tsx": () => import("./../../../src/pages/about/process.tsx" /* webpackChunkName: "component---src-pages-about-process-tsx" */),
  "component---src-pages-about-reviews-tsx": () => import("./../../../src/pages/about/reviews.tsx" /* webpackChunkName: "component---src-pages-about-reviews-tsx" */),
  "component---src-pages-cart-checkout-tsx": () => import("./../../../src/pages/cart/checkout.tsx" /* webpackChunkName: "component---src-pages-cart-checkout-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-checkout-order-received-order-id-tsx": () => import("./../../../src/pages/checkout/order-received/[orderId].tsx" /* webpackChunkName: "component---src-pages-checkout-order-received-order-id-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-helping-cats-tsx": () => import("./../../../src/pages/helping/cats.tsx" /* webpackChunkName: "component---src-pages-helping-cats-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-care-tsx": () => import("./../../../src/pages/info/care.tsx" /* webpackChunkName: "component---src-pages-info-care-tsx" */),
  "component---src-pages-info-delivery-tsx": () => import("./../../../src/pages/info/delivery.tsx" /* webpackChunkName: "component---src-pages-info-delivery-tsx" */),
  "component---src-pages-info-index-tsx": () => import("./../../../src/pages/info/index.tsx" /* webpackChunkName: "component---src-pages-info-index-tsx" */),
  "component---src-pages-info-legal-tsx": () => import("./../../../src/pages/info/legal.tsx" /* webpackChunkName: "component---src-pages-info-legal-tsx" */),
  "component---src-pages-info-payments-tsx": () => import("./../../../src/pages/info/payments.tsx" /* webpackChunkName: "component---src-pages-info-payments-tsx" */),
  "component---src-pages-info-returns-tsx": () => import("./../../../src/pages/info/returns.tsx" /* webpackChunkName: "component---src-pages-info-returns-tsx" */),
  "component---src-pages-info-sizing-tsx": () => import("./../../../src/pages/info/sizing.tsx" /* webpackChunkName: "component---src-pages-info-sizing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-public-offer-tsx": () => import("./../../../src/pages/public-offer.tsx" /* webpackChunkName: "component---src-pages-public-offer-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

