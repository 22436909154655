import { Tuple, DefaultMantineColor } from "@mantine/core";

type ExtendedCustomColors =
	| "brandPrimary"
	| "brandSecondary"
	| "brandTertiary"
	| DefaultMantineColor;

declare module "@mantine/core" {
	export interface MantineThemeColorsOverride {
		colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
	}
}

export const colors: Partial<Record<ExtendedCustomColors, Tuple<string, 10>>> =
	{
		// brandPrimary: [
		// 	"#fdfaf7",
		// 	"#F9F5F1",
		// 	"#EEE3D8",
		// 	"#E4D3C1",
		// 	"#DAC4AB",
		// 	"#D2B597",
		// 	"#CAA884",
		// 	/* #7 (brandPrimary): */ "#C29C73",
		// 	"#BA9062",
		// 	"#B48552",
		// ],

		//adjusted to try and match lighter color at primary position
		brandPrimary: [
			"#F3ECE0",
			"#E9DBC7",
			"#E0CCB0",
			"#D7BE9B",
			"#CEB288",
			"#C8A87C",
			"#C5A578",
			"#BD9E72",
			"#B29468",
			"#A78A5E",
		],

		brandSecondary: [
			"#FAF0F4", // lightest tint
			"#F5E1E9",
			"#F0D2DE",
			"#E5BCC7", // (brand) provided color at 4th position
			"#DAA7B2",
			"#CF929D",
			"#C47D88",
			"#B96874",
			"#AE5360",
			"#A33E4C", // darkest shade
		],

		brandTertiary: [
			"#F3F2F1",
			"#DEDBD9",
			"#C8C3C1",
			"#B3ACA8",
			"#9D9490",
			"#887D77",
			"#6D645F",
			"#514B48",
			"#363230",
			"#1B1918",
		],

		// dark: [
		// 	"#3A3938",
		// 	"#333130",
		// 	"#2D2B2A",
		// 	"#272524",
		// 	"#242120",
		// 	"#201E1D",
		// 	"#1E1C1A",
		// 	"#1B1918", //primary shade #7
		// 	"#181716",
		// 	"#161414",
		// ],

		// dark: [
		// 	"#efece9", //gray 2
		// 	"#2C2828",
		// 	"#252222",
		// 	"#1F1C1C",
		// 	"#1A1818",
		// 	"#161414",
		// 	"#121010",
		// 	"#0E0D0D", //primary shade #7
		// 	"#0C0B0B",
		// 	"#090909",
		// ],

		//based on radix scales
		// dark: [
		// 	"#ededed",
		// 	"#a0a0a0",
		// 	// "#7e7e7e",
		// 	// "#707070",
		// 	// "#505050",
		// 	// "#3e3e3e",
		// 	"#343434",
		// 	"#2e2e2e",
		// 	"#282828", // input border
		// 	"#232323",
		// 	"#1c1c1c", // input background [6]
		// 	"#161616", // [7]
		// 	"#161616",
		// 	"#161616",
		// ],

		//radix 2, adjusted comments only
		//THIS IS THE ORIGINAL DARK BEFORE EDITING 6/29/23 !!!!!
		dark: [
			"#ededed",
			"#a0a0a0",
			// "#7e7e7e",
			"#707070",
			"#505050",
			// "#3e3e3e",
			//"#343434",
			"#2e2e2e",
			//"#282828", // input border
			"#232323",
			"#1c1c1c", // input background [6]
			"#161616", // [7]
			"#161616",
			"#161616",
		],

		// dark: [
		// 	"#ededed",
		// 	"#a0a0a0",
		// 	"#707070",
		// 	"#505050",
		// 	"#2e2e2e",
		// 	"#232323",
		// 	"#1c1c1c",
		// 	"#161616",
		// 	"#161616",
		// 	"#161616",
		// ],

		gray: [
			"#fbfbfb",
			// "#f8f8f8",
			"#f3f3f3",
			"#ededed",
			"#e8e8e8",
			"#dbdbdb",
			"#c7c7c7",
			"#8f8f8f",
			"#858585",
			"#6f6f6f",
			"#171717",
		],
	};
