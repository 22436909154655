import { Button } from "./Button";
import { Text } from "./Text";
import { Container } from "./Container";
import { Select } from "./Select";
import { Anchor } from "./Anchor";
import { Loader } from "./Loader";
import { LoadingOverlay } from "./LoadingOverlay";

export const defaults = {
	Button,
	Text,
	Container,
	Select,
	Anchor,
	Loader,
	LoadingOverlay,
};
