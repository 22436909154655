import {
	Box,
	Container,
	Grid,
	MediaQuery,
	SimpleGrid,
	Text,
	rem,
	Accordion,
	Anchor,
	ActionIcon,
	Stack,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import LoonaLogo from "@assets/logo.svg";
import { useStyles } from "./Footer.styles";
import { footerLinks } from "./footer-links";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
	IconBrandInstagram,
	IconBrandTelegram,
	IconBrandVk,
	IconBrandYoutube,
	IconBrandWhatsapp,
	IconClock,
	IconMail,
	IconPhone,
} from "@tabler/icons-react";
import { useLocation } from "@reach/router";
import useGetOptions from "@hooks/useGetOptions";

const Footer = () => {
	const { classes } = useStyles();
	const [accordionValue, setAccordionValue] = useState<string | null>(null);
	const location = useLocation();

	const { socialMedia, companyName, email, phone, workingHours } =
		useGetOptions();

	useEffect(() => {
		if (accordionValue) {
			setAccordionValue(null);
		}
	}, [location.pathname]);

	return (
		<Box component="footer" className={classes.root}>
			<Container>
				<Grid grow>
					<Grid.Col
						span={12}
						xs={6}
						sm={12}
						md={3}
						className={classes.col}
					>
						<Box className={classes.logoWrapper}>
							<LoonaLogo className={classes.logo} />
						</Box>
						<Box className={classes.socialWrapper}>
							{socialMedia?.instagram && (
								<ActionIcon
									component="a"
									href={socialMedia.instagram}
									target="_blank"
									color="brandPrimary"
									variant="transparent"
									className={classes.socialIcon}
								>
									<IconBrandInstagram
										size={26}
										strokeWidth={1.5}
										style={{ color: "unset" }}
									/>
								</ActionIcon>
							)}
							{socialMedia?.telegram && (
								<ActionIcon
									component="a"
									href={socialMedia.telegram}
									target="_blank"
									color="dark"
									variant="transparent"
									className={classes.socialIcon}
								>
									<IconBrandTelegram
										size={26}
										strokeWidth={1.5}
										style={{ color: "unset" }}
									/>
								</ActionIcon>
							)}
							{socialMedia?.vk && (
								<ActionIcon
									component="a"
									href={socialMedia.vk}
									target="_blank"
									color="dark"
									variant="transparent"
									className={classes.socialIcon}
								>
									<IconBrandVk
										size={26}
										strokeWidth={1.5}
										style={{ color: "unset" }}
									/>
								</ActionIcon>
							)}
							{socialMedia?.youtube && (
								<ActionIcon
									component="a"
									href={socialMedia.youtube}
									target="_blank"
									color="dark"
									variant="transparent"
									className={classes.socialIcon}
								>
									<IconBrandYoutube
										size={26}
										strokeWidth={1.5}
										style={{ color: "unset" }}
									/>
								</ActionIcon>
							)}
							{socialMedia?.whatsapp && (
								<ActionIcon
									component="a"
									href={socialMedia.whatsapp}
									target="_blank"
									color="dark"
									variant="transparent"
									className={classes.socialIcon}
								>
									<IconBrandWhatsapp
										size={26}
										strokeWidth={1.5}
										style={{ color: "unset" }}
									/>
								</ActionIcon>
							)}
						</Box>
					</Grid.Col>
					<Grid.Col span={12} xs={6} sm={4} md={3} orderSm={4}>
						<Box className={classes.contactWrapper}>
							<Text variant="subtitle" className={classes.colTitle}>
								КОНТАКТЫ
							</Text>
							<Text className={classes.colText}>
								<IconClock
									className={classes.linkIcon}
									size={rem(16)}
									strokeWidth={1.5}
								/>
								{workingHours}
							</Text>
							<Box component="a" href={`mailto:${email}`}>
								<Text className={classes.linkText}>
									<IconMail
										className={classes.linkIcon}
										size={rem(16)}
										strokeWidth={1.5}
									/>
									{email}
								</Text>
							</Box>
							<Box
								component="a"
								href={`tel:${phone.replace(/[^\d]+/g, "")}`}
							>
								<Text className={classes.linkText}>
									<IconPhone
										className={classes.linkIcon}
										size={rem(16)}
										strokeWidth={1.5}
									/>
									{phone}
								</Text>
							</Box>
							<Text
								variant="subtitle"
								className={classes.colTitle}
								mt="xs"
							>
								Служба заботы Loona
							</Text>
							<Text size="xs" className={classes.colText}>
								WhatsApp / Telegram
							</Text>
							<Text className={classes.colText}>+7 (916) 767-42-24</Text>
							<MediaQuery largerThan="xs" styles={{ textAlign: "left" }}>
								<Box mt="md" sx={{ textAlign: "center" }}>
									<Text size="sm" color="dimmed" align="inherit">
										ИП Монтуляк Ольга Сергеевна
									</Text>
									<Text size="sm" color="dimmed" align="inherit">
										ИНН 772482899490
									</Text>
									<Text size="sm" color="dimmed" align="inherit">
										ОГРНИП 320774600007389
									</Text>
								</Box>
							</MediaQuery>
						</Box>
					</Grid.Col>
					<MediaQuery largerThan={"xs"} styles={{ display: "none" }}>
						<Grid.Col span={12} className={classes.col}>
							<Accordion
								value={accordionValue}
								onChange={setAccordionValue}
							>
								<Accordion.Item
									value="main"
									id="footer-control-main-item"
								>
									<Accordion.Control id="footer-control-main">
										<Text
											variant="subtitle"
											className={classes.colTitle}
											mr={rem(-44)}
										>
											Основные
										</Text>
									</Accordion.Control>
									<Accordion.Panel>
										<Box component="ul" className={classes.ul}>
											{footerLinks.main.map((item) => (
												<Box
													component="li"
													key={item.name}
													className={classes.li}
												>
													<Link
														to={item.link}
														className={classes.link}
													>
														<Text className={classes.linkText}>
															{item.label}
														</Text>
													</Link>
												</Box>
											))}
										</Box>
									</Accordion.Panel>
								</Accordion.Item>
								<Accordion.Item
									value="help"
									id="footer-control-help-item"
								>
									<Accordion.Control id="footer-control-help">
										<Text
											variant="subtitle"
											className={classes.colTitle}
											mr={rem(-44)}
										>
											Полезное
										</Text>
									</Accordion.Control>
									<Accordion.Panel>
										<Box component="ul" className={classes.ul}>
											{footerLinks.help.map((item) => (
												<Box
													component="li"
													key={item.name}
													className={classes.li}
												>
													<Link
														to={item.link}
														className={classes.link}
													>
														<Text className={classes.linkText}>
															{item.label}
														</Text>
													</Link>
												</Box>
											))}
										</Box>
									</Accordion.Panel>
								</Accordion.Item>
							</Accordion>
						</Grid.Col>
					</MediaQuery>
					<MediaQuery smallerThan={"xs"} styles={{ display: "none" }}>
						<Grid.Col
							span={12}
							xs={6}
							sm={4}
							md={3}
							className={classes.col}
						>
							<Text variant="subtitle" className={classes.colTitle}>
								Основные
							</Text>
							<Box component="ul" className={classes.ul}>
								{footerLinks.main.map((item) => (
									<Box
										component="li"
										key={item.name}
										className={classes.li}
									>
										<Link to={item.link} className={classes.link}>
											<Text className={classes.linkText}>
												{item.label}
											</Text>
										</Link>
									</Box>
								))}
							</Box>
						</Grid.Col>
					</MediaQuery>

					<MediaQuery smallerThan={"xs"} styles={{ display: "none" }}>
						<Grid.Col
							span={12}
							xs={6}
							sm={4}
							md={3}
							className={classes.col}
						>
							<Text variant="subtitle" className={classes.colTitle}>
								Полезное
							</Text>
							<Box component="ul" className={classes.ul}>
								{footerLinks.help.map((item) => (
									<Box
										component="li"
										key={item.name}
										className={classes.li}
									>
										<Link to={item.link} className={classes.link}>
											<Text className={classes.linkText}>
												{item.label}
											</Text>
										</Link>
									</Box>
								))}
							</Box>
						</Grid.Col>
					</MediaQuery>
				</Grid>
				<SimpleGrid
					className={classes.bot}
					cols={1}
					spacing={rem(7)}
					breakpoints={[{ minWidth: "xs", cols: 2 }]}
				>
					<Box>
						<Text className={classes.leftText}>
							&copy; {new Date().getFullYear()} Все права защищены.
						</Text>
						<Text className={classes.leftText}>
							Разработка сайта{" "}
							<Anchor
								className={classes.copyLink}
								href="https://boldmedia.ru"
								target="_blank"
							>
								Boldmedia
							</Anchor>
						</Text>
					</Box>
					<Box>
						<Text className={classes.rightText}>
							<Anchor
								component={Link}
								className={classes.copyLink}
								to="/privacy-policy"
							>
								Политика конфедициальности
							</Anchor>
						</Text>
						<Text className={classes.rightText}>
							{/* <Anchor
								className={classes.copyLink}
								component={Link}
								to="/info/legal"
								mr="md"
							>
								Реквизиты
							</Anchor> */}
							<Anchor
								className={classes.copyLink}
								component={Link}
								to="/public-offer"
							>
								Публичная оферта
							</Anchor>
						</Text>
					</Box>
				</SimpleGrid>
			</Container>
		</Box>
	);
};

export default Footer;
