import type {
  MantineThemeOverride,
  ContainerProps,
  MantineTheme,
  CSSObject,
} from "@mantine/core";

export const Container: MantineThemeOverride["components"]["Container"] = {
  defaultProps: {
    size: "lg",
  },
};
