import { Switch, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons-react";
import React from "react";
import { useStyles } from "./HeaderIcons.styles";

interface ThemeSwitcherProps {
	className?: string;
}

const ThemeSwitcher = ({ className }: ThemeSwitcherProps) => {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const { classes, cx } = useStyles();

	return (
		<Switch
			id="colorSwitcher"
			// mr={rem(4)}
			checked={colorScheme === "dark"}
			color="gray"
			size="md"
			onChange={() => toggleColorScheme()}
			onLabel={
				<IconSun
					color={theme.colors.brandPrimary[7]}
					size="1.25rem"
					stroke={1.5}
				/>
			}
			offLabel={
				<IconMoonStars
					color={theme.colors.gray[5]}
					size="1.25rem"
					stroke={1.5}
				/>
			}
			classNames={{
				root: cx(classes.colorSchemeRoot, className),
				input: classes.colorSchemeInput,
				thumb: classes.colorSchemeThumb,
				track: classes.colorSchemeTrack,
			}}
		/>
	);
};

export default ThemeSwitcher;
