import { getProductById } from "@helpers/getProductById";
import React, { useMemo } from "react";
import { CartItem, useCartActions } from "src/store/cartStore";
import {
	Product,
	ProductAttribute,
	ProductImage,
	ProductVariation,
} from "src/types/product.types";
import {
	Card,
	Text,
	Badge,
	Box,
	rem,
	CloseButton,
	ActionIcon,
	AspectRatio,
	Anchor,
} from "@mantine/core";
import { useStyles } from "./CartItems.style";
import { GatsbyImage } from "gatsby-plugin-image";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { Link } from "gatsby";
import { shallow } from "zustand/shallow";
import { useProductVariations } from "@hooks/useProductVariations";
import { useVariationImageIds } from "@hooks/useVariationImageIds";
import { useCartItemData } from "@hooks/useCartItemData";
import { useFormatAttributes } from "@hooks/useFormatAttributes";
import { attributeKeys } from "@constants/attributeKeys";

interface SingleCartItemProps {
	item: CartItem;
	products: Product[];
	size: "xs" | "md";
}

const SingleCartItem = ({ item, products, size }: SingleCartItemProps) => {
	const { classes, theme } = useStyles({ size });
	const { updateCart } = useCartActions();

	const {
		product,
		currentVariation,
		currentBundleVariations,
		selectedAttributes,
		selectedBundleAttributes,
	} = useCartItemData({ cartItem: item, products });

	const filterImageIds = useVariationImageIds({
		productType: item.type,
		currentVariation: currentVariation,
		currentBundleVariations: currentBundleVariations,
		selectedAttributes: selectedAttributes,
	});

	const image: ProductImage = useMemo(() => {
		if (filterImageIds) {
			const filteredImages: Array<ProductImage> = product?.images?.filter(
				(image) => filterImageIds.includes(image?.wpImgId)
			);

			return filteredImages[0];
		} else {
			return product.images[0];
		}
	}, [filterImageIds]);

	let uniqueAttributes: Array<{ key: string; value: string }> = [];

	// Go through all selectedAttributes
	Object.entries(selectedAttributes).forEach(([key, value]) => {
		uniqueAttributes.push({ key, value });
	});

	// Go through each of the selectedBundleAttributes
	Object.entries(selectedBundleAttributes).forEach(
		([productId, attributes]) => {
			Object.entries(attributes).forEach(([key, value]) => {
				if (
					!uniqueAttributes.some(
						(attr) => attr.key === key && attr.value === value
					)
				) {
					uniqueAttributes.push({ key, value });
				}
			});
		}
	);

	// Render badges for unique attributes
	const badges = uniqueAttributes.map((attr, index) => (
		<Badge
			key={index}
			size={size === "xs" ? "xs" : "md"}
			color="brandPrimary"
			mr={rem(4)}
		>
			{attr.value}
		</Badge>
	));

	return (
		<Card
			className={classes.productCard}
			withBorder
			p={size === "xs" ? rem(7) : "sm"}
		>
			<Box className={classes.productImageWrapper}>
				<AspectRatio ratio={2 / 3}>
					<GatsbyImage
						image={image.fileNode.childImageSharp.gatsbyImageData}
						alt={image?.alt || product?.name}
						// className={classes.productImage}
					/>
				</AspectRatio>
			</Box>

			<Box className={classes.cardContentWrapper}>
				<Box className={classes.cardContent}>
					<Anchor
						component={Link}
						underline={false}
						color={theme.colorScheme === "dark" ? "white" : "dark"}
						to={`/product/${product?.slug}`}
					>
						<Text
							variant="subtitle"
							lineClamp={2}
							className={classes.productName}
						>
							{product?.name}
						</Text>
					</Anchor>
					<Box>{badges}</Box>
					<Text className={classes.itemPrice}>{item.total} &#8381;</Text>
				</Box>

				<Box className={classes.qtyWrapper}>
					<ActionIcon
						size={size === "xs" ? "sm" : "md"}
						color="brandPrimary"
						onClick={() =>
							updateCart({
								type: item.type,
								productId: item.productId,
								variationId: item.variationId,
								bundleItems: item.bundleItems,
								quantity: (currentQty) => currentQty - 1,
								price: product?.variation?.price || product?.price,
							})
						}
					>
						<IconMinus strokeWidth={1.5} size={size === "xs" ? 14 : 18} />
					</ActionIcon>
					<Text variant="subtitle" className={classes.quantity}>
						{item?.quantity}
						{size === "xs" ? null : "шт."}
					</Text>
					<ActionIcon
						size={size === "xs" ? "sm" : "md"}
						color="brandPrimary"
						onClick={() =>
							updateCart({
								type: item.type,
								productId: item.productId,
								variationId: item.variationId,
								bundleItems: item.bundleItems,
								quantity: (currentQty) => currentQty + 1,
								price: product?.variation?.price || product?.price,
							})
						}
					>
						<IconPlus strokeWidth={1.5} size={size === "xs" ? 14 : 18} />
					</ActionIcon>
				</Box>
				<CloseButton
					className={classes.close}
					onClick={() =>
						updateCart({
							type: item.type,
							productId: item.productId,
							variationId: item.variationId,
							bundleItems: item.bundleItems,
							quantity: 0,
							price: product?.variation?.price || product?.price,
						})
					}
				/>
			</Box>
		</Card>
	);
};

export default SingleCartItem;
