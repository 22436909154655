import { createStyles, rem, em } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		position: "relative",
	},

	marqueeText: {
		fontSize: rem(14),
		textTransform: "uppercase",
		fontWeight: 500,

		// [theme.fn.largerThan(em(360))]: {
		// 	fontSize: rem(16),
		// },

		[theme.fn.largerThan("xs")]: {
			fontSize: rem(16),
		},
	},

	bgRoot: {
		paddingTop: rem(42),
		// paddingBottom: rem(0),
		paddingBottom: rem(42),
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[1],
	},

	title: {
		fontSize: rem(14),
		textTransform: "uppercase",
		fontWeight: 600,
		marginBottom: theme.spacing.md,
		textAlign: "center",
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[0]
				: theme.colors.gray[8],

		[theme.fn.largerThan(em(300))]: {
			fontSize: rem(16),
		},

		[theme.fn.largerThan(em(360))]: {
			fontSize: rem(18),
		},

		[theme.fn.largerThan("xs")]: {
			fontSize: rem(20),
		},
	},

	wrapper: {
		position: "relative",
		zIndex: 2,
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing.md,
		alignItems: "center",

		[theme.fn.largerThan("sm")]: {
			flexDirection: "row",
		},
	},

	imgWrapper: {
		flex: 1,

		[theme.fn.largerThan("sm")]: {
			flex: 0.5,
		},
		// [theme.fn.largerThan("lg")]: {
		// 	flex: 0.55,
		// },
	},

	form: {
		flex: 1,
		justifyContent: "center",
		maxWidth: rem(472),

		[theme.fn.largerThan("sm")]: {
			flex: 0.5,
			// maxWidth: "initial",
		},

		// [theme.fn.largerThan("lg")]: {
		// 	flex: 0.45,
		// },
	},

	formTitle: {
		textAlign: "center",
		color:
			theme.colorScheme === "dark"
				? theme.colors.dark[0]
				: theme.colors.gray[8],
		[theme.fn.largerThan("sm")]: {
			textAlign: "left",
		},
	},

	emailWrapper: {
		display: "flex",
	},

	inputRoot: {
		position: "relative",
	},

	inputWrapper: {
		marginBottom: 0,
	},

	inputError: {
		position: "absolute",
		bottom: `-${rem(16)}`,
	},

	formPolicy: {
		textAlign: "center",
		[theme.fn.largerThan("sm")]: {
			textAlign: "left",
		},
	},

	image: {
		maxWidth: rem(442),

		[theme.fn.largerThan("sm")]: {
			maxWidth: "initial",
		},

		[theme.fn.largerThan("md")]: {
			// marginRight: rem(42),
		},
	},
}));
