import React from "react";
import { Link } from "gatsby";
import LoonaLogo from "@assets/logo.svg";
import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	root: {
		display: "block",
		fill:
			theme.colorScheme === "dark"
				? theme.colors.gray[0]
				: theme.colors.dark[7],

		height: rem(24),
		transform: `translateY(-12%)`,
		transition: `fill 0.24s ${theme.transitionTimingFunction}`,

		[theme.fn.largerThan("xs")]: {
			height: rem(27),
		},

		"&:hover": {
			fill:
				theme.colorScheme === "dark"
					? theme.colors.gray[1]
					: theme.colors.dark[6],
		},
	},

	logoLink: {
		display: "flex",
		alignItems: "center",
		borderRadius: theme.radius.xs,
		...theme.fn.focusStyles(),
	},
}));

const HeaderLogo = () => {
	const { classes } = useStyles();

	return (
		<Link to="/" className={classes.logoLink}>
			<LoonaLogo className={classes.root} />
		</Link>
	);
};

export default HeaderLogo;
