import { createStyles, em, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		position: "fixed",
		height: `var(--header-height, ${rem(72)})`,
		width: "100%",
		borderBottom: `${rem(1)} solid ${
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[2]
		}`,
		backgroundColor:
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		paddingLeft: rem(16),
		paddingRight: rem(16),
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		zIndex: 700,
	},

	outerWrapper: {
		width: rem(172),
		display: "flex",

		"&:nth-of-type(3)": {
			justifyContent: "flex-end",
		},

		[theme.fn.smallerThan(em(1024))]: {
			width: "auto",
		},
	},

	innerWrapper: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		height: "100%",
	},

	spacer: {
		height: `var(--header-height, ${rem(72)})`,
	},
}));
