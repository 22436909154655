import {
	Indicator,
	Popover,
	Text,
	UnstyledButton,
	rem,
	Drawer,
	em,
	Box,
	Button,
	ScrollArea,
	CloseButton,
} from "@mantine/core";
import {
	useDisclosure,
	useLocalStorage,
	useMediaQuery,
	useWindowEvent,
} from "@mantine/hooks";
import { IconShoppingBag } from "@tabler/icons-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStyles } from "./HeaderIcons.styles";
import { useCartStore, useCartActions } from "src/store/cartStore";
import { CartItems } from "@modules/CartItems";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { shallow } from "zustand/shallow";
import { api } from "@helpers/axios";

const slide = {
	in: { transform: "translateX(0%)" },
	out: { transform: "translateX(100%)" },
	common: { transformOrigin: "bottom" },
	transitionProperty: "transform",
};

const slideLeft = {
	in: { transform: "translateX(0%)", opacity: 1 },
	out: { transform: "translateX(20%)", opacity: 0 },
	common: { transformOrigin: "right" },
	transitionProperty: "transform",
};

const Cart = () => {
	const { classes, theme } = useStyles();
	const [opened, { close, open, toggle }] = useDisclosure();

	const cartItems = useCartStore((state) => state.cartItems, shallow);
	const itemAdded = useCartStore((state) => state.itemAdded, shallow);
	const closeOnClickOutside = useCartStore(
		(state) => state.closeOnClickOutside,
		shallow
	);
	const { setItemAdded, clearCart } = useCartActions();
	const initialRenderRef = useRef(true);
	const [shouldClearCart, setShouldClearCart] = useState(false);

	const cleanUpLocalStorage = async () => {
		console.log("Cleaning up local storage...");
		const orderForm = JSON.parse(localStorage.getItem("order-form"));
		let orderId;
		if (orderForm?.orderId) {
			orderId = orderForm?.orderId;
			console.log(`Reading orderId: ${orderId}`);
		}
		if (orderId) {
			const response = await api.get(`/only-order-status/${orderId}`);

			if (
				response?.data?.order_status === "processing" ||
				response?.data?.order_status === "completed" ||
				response?.data?.order_status === "on-hold"
			) {
				console.log(
					`orderId: ${orderId} is processing. Attempting to clean local storage.`
				);
				localStorage.setItem(
					"order-form",
					JSON.stringify({
						...orderForm,
						orderId: "",
						orderComplete: false,
					})
				);
				// clearCart();
				setShouldClearCart(true);
			}
		}
	};

	useWindowEvent("focus", cleanUpLocalStorage);

	useEffect(() => {
		if (initialRenderRef.current) {
			cleanUpLocalStorage();
			initialRenderRef.current = false;
		}
	}, []);

	useEffect(() => {
		if (shouldClearCart) {
			clearCart();
			setShouldClearCart(false);
		}
	}, [shouldClearCart]);

	const totalQty = useMemo(() => {
		if (!cartItems) return 0;
		return cartItems.reduce((total, item) => total + item.quantity, 0);
	}, [cartItems]);
	const timer = useRef(null);
	const isDesktop = useMediaQuery(`(min-width: ${em(768)})`);
	const isMobile = useMediaQuery(`(max-width: ${em(520)})`);
	const isNotBlocking = useMediaQuery(`(min-width: ${em(1400)})`);
	const location = useLocation();

	useEffect(() => {
		if (
			itemAdded &&
			isDesktop &&
			location.pathname !== "/cart/" &&
			isNotBlocking
		) {
			open();
			clearTimeout(timer.current);

			timer.current = setTimeout(() => {
				close();
			}, 3000);
			setItemAdded(false);
		}
	}, [itemAdded, setItemAdded, totalQty]);

	const handleClose = () => {
		close();
		if (timer.current) {
			setItemAdded(false);
			clearTimeout(timer.current);
		}
	};

	let closeTimeout = null;

	const handleMouseLeave = () => {
		if (isDesktop && location.pathname !== "/cart/checkout/") {
			closeTimeout = setTimeout(() => {
				close();
			}, 272);
		}
	};

	const handleMouseEnter = () => {
		if (closeTimeout) {
			clearTimeout(closeTimeout);
			closeTimeout = null;
		}

		if (isDesktop && !location.pathname.startsWith("/cart")) {
			open();
			clearTimeout(timer.current);
		}
	};

	const handleClick = () => {
		if (location.pathname.startsWith("/cart")) {
			navigate("/cart");
		} else {
			if (isDesktop || isMobile) {
				navigate("/cart");
			} else {
				open();
			}
		}
	};

	useEffect(() => {
		close();
	}, [location.pathname]);

	return (
		<>
			<Popover
				opened={opened && isDesktop}
				closeOnClickOutside={closeOnClickOutside}
				offset={{ mainAxis: 32, crossAxis: -148 }}
				width={342}
				onClose={handleClose}
				radius="xs"
				transitionProps={{ transition: slideLeft, duration: 342 }}
			>
				<Popover.Target>
					<UnstyledButton
						id="cart-btn-1"
						className={classes.actionIcon}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						onClick={handleClick}
					>
						<Indicator
							id="cart-btn-indicator"
							color="brandPrimary"
							label={
								<Text
									id="cart-btn-indicator-items"
									size={rem(9)}
									weight={500}
								>
									{totalQty}
								</Text>
							}
							size="xs"
							disabled={!totalQty}
						>
							<IconShoppingBag
								id="cart-button-shopping-bag-icon"
								size={rem(28)}
								strokeWidth={1.42}
							/>
						</Indicator>
					</UnstyledButton>
				</Popover.Target>
				<Popover.Dropdown
					id="popover-dropdown"
					p="xs"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<ScrollArea.Autosize mah={300} mx="auto" type="never">
						<CartItems size="xs" />
					</ScrollArea.Autosize>

					{totalQty > 0 ? (
						<Box className={classes.btnWrapper} mt="xs">
							<Link to="/cart">
								<Button
									id="popover-btn-cart"
									color={
										theme.colorScheme === "dark" ? "white" : "dark"
									}
									variant="outline"
									fullWidth
								>
									Перейти в корзину
								</Button>
							</Link>
							<Link to="/cart/checkout">
								<Button
									color="brandPrimary"
									fullWidth
									id="popover-btn-checkout"
								>
									Оформить заказ
								</Button>
							</Link>
						</Box>
					) : null}
				</Popover.Dropdown>
			</Popover>
			<Drawer
				opened={opened && !isDesktop}
				onClose={close}
				withCloseButton={false}
				position="right"
				zIndex={2000}
				classNames={{ body: classes.drawer }}
				transitionProps={{ transition: slide, duration: 342 }}
				overlayProps={{ opacity: 0.36, blur: 0 }}
			>
				<Box className={classes.drawerContent}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							paddingBottom: theme.spacing.md,
						}}
					>
						<Text variant="subtitle" sx={{ fontSize: 14 }}>
							корзина
						</Text>
						<CloseButton onClick={close} />
					</Box>
					<ScrollArea type="never" h="100%">
						<CartItems size="xs" />
					</ScrollArea>
				</Box>

				<Box className={classes.btnWrapper} mt="md">
					<Link to="/cart">
						<Button
							id="cart-btn-cart"
							color={theme.colorScheme === "dark" ? "white" : "dark"}
							variant="outline"
							fullWidth
						>
							Перейти в корзину
						</Button>
					</Link>
					<Link to="/cart/checkout">
						<Button id="cart-btn-checkout" color="brandPrimary" fullWidth>
							Оформить заказ
						</Button>
					</Link>
				</Box>
			</Drawer>
		</>
	);
};

export default Cart;
