import { MantineThemeOverride } from "@mantine/core";

export const Select: MantineThemeOverride["components"]["Select"] = {
	styles: (theme) => ({
		item: {
			"&[data-selected]": {
				"&, &:hover": {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.brandPrimary[9]
							: theme.colors.brandPrimary[0],
					color:
						theme.colorScheme === "dark"
							? theme.white
							: theme.colors.brandPrimary[9],
				},
			},
			"&[data-hovered]": {},
		},
	}),
};
