export const footerLinks = {
	main: [
		{
			name: "home",
			label: "Главная",
			link: "/",
		},
		{
			name: "shop",
			label: "Каталог",
			link: "/shop",
		},
		{
			name: "company",
			label: "Философия",
			link: "/about/company",
		},
		{
			name: "process",
			label: "Наше производство",
			link: "/about/process",
		},
		{
			name: "savingCats",
			label: "Мяу",
			link: "/helping/cats/",
		},
		{
			name: "contact",
			label: "Контакты",
			link: "/contact",
		},
	],

	help: [
		{
			name: "sizing",
			label: "Размеры",
			link: "/info/sizing",
		},
		// {
		// 	name: "care",
		// 	label: "Уход за одеждой",
		// 	link: "/info/care",
		// },
		{
			name: "delivery",
			label: "Доставка",
			link: "/info/delivery",
		},
		{
			name: "payments",
			label: "Оплата",
			link: "/info/payments",
		},
		{
			name: "returns",
			label: "Возврат",
			link: "/info/returns",
		},
	],
};
