import {
	MantineThemeOverride,
	CSSObject,
	AnchorProps,
	rem,
} from "@mantine/core";

export const Anchor: MantineThemeOverride["components"]["Anchor"] = {
	styles: (theme, { color }) => ({
		root: {
			"&:visited": {
				color:
					color === "brandPrimary"
						? theme.colorScheme === "dark"
							? theme.colors.brandPrimary[7]
							: theme.colors.brandPrimary[6]
						: undefined,
			},
		},
	}),
};
