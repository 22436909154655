import React from "react";
import { CartItem } from "src/store/cartStore";
import {
	Product,
	ProductBase,
	ProductVariation,
} from "src/types/product.types";
import { useFormatAttributes } from "./useFormatAttributes";
import { useProductVariations } from "./useProductVariations";
import { attributeKeys } from "@constants/attributeKeys";

interface UseCartItemDataProps {
	cartItem: CartItem;
	products: Product[];
}

interface UseCartItemDataReturn {
	product: Product;
	selectedAttributes: {
		[key: string]: string;
	};
	selectedBundleAttributes: {
		[key: ProductBase["wcProductId"]]: { [key: string]: string };
	};
	currentVariation: ProductVariation;
	currentBundleVariations: {
		[key: ProductBase["wcProductId"]]: ProductVariation;
	};
}

export const useCartItemData = ({
	cartItem,
	products,
}: UseCartItemDataProps): UseCartItemDataReturn => {
	const product = products.find(
		(product) => product.wcProductId === cartItem.productId
	);
	if (!product) {
		throw new Error(
			`Product with wcProductId ${cartItem.productId} not found.`
		);
	}

	const formattedAttributes = useFormatAttributes({
		type: product.type,
		defaultWpAttributes: product.default_attributes,
		attributes: product.attributes,
		bundledProducts: product.bundledProducts || [],
	});

	// Get the selected variation for the main product
	const selectedVariation = product.variations.find(
		(variation) => variation.id === cartItem.variationId
	);

	let selectedAttributes = {};
	if (selectedVariation) {
		selectedAttributes = selectedVariation.attributes.reduce((obj, attr) => {
			const key = attributeKeys[attr.id] || attr.name;
			obj[key] = attr.option;
			return obj;
		}, {});
	}

	// Get the selected variations for the bundle items
	const selectedBundleAttributes = cartItem.bundleItems.reduce(
		(obj, bundleItem) => {
			const bundleProduct = product.bundledProducts.find(
				(bp) => bp.wcProductId === bundleItem.productId
			);
			if (bundleProduct) {
				const selectedBundleVariation = bundleProduct.variations.find(
					(variation) => variation.id === bundleItem.variationId
				);
				if (selectedBundleVariation) {
					obj[bundleItem.productId] =
						selectedBundleVariation.attributes.reduce((obj, attr) => {
							const key = attributeKeys[attr.id] || attr.name;
							obj[key] = attr.option;
							return obj;
						}, {});
				}
			}
			return obj;
		},
		{}
	);

	// Get selected variations
	const { currentVariation, currentBundleVariations } = useProductVariations({
		type: product.type,
		variations: product.variations,
		attributes: formattedAttributes,
		bundledProducts: product.bundledProducts,
		overrideSelectedAttributes: selectedAttributes,
		overrideSelectedBundleAttributes: selectedBundleAttributes,
	});

	return {
		product,
		selectedAttributes,
		selectedBundleAttributes,
		currentVariation,
		currentBundleVariations,
	};
};
