import type {
	MantineThemeOverride,
	CSSObject,
	MantineGradient,
	MantineTheme,
	MantineColor,
} from "@mantine/core";
import { rem } from "@mantine/core";

// const sizes = {
//   xs: { height: rem(27), paddingLeft: rem(18), paddingRight: rem(18) },
//   sm: { height: rem(36), paddingLeft: rem(24), paddingRight: rem(24) },
//   md: { height: rem(42), paddingLeft: rem(28), paddingRight: rem(28) },
//   lg: { height: rem(48), paddingLeft: rem(32), paddingRight: rem(32) },
//   xl: { height: rem(54), paddingLeft: rem(36), paddingRight: rem(36) },
// };

export const BUTTON_VARIANTS = [
	"filled",
	"outline",
	"light",
	"white",
	"default",
	"subtle",
	"gradient",
];

function getSizes({ height, aspectRatio, percentChange }) {
	const baseWidth = (height * aspectRatio) / 2;

	const sizes = {
		xs: {
			height: rem(height - height * (percentChange / 100) * 2),
			paddingLeft: rem(baseWidth * 0.4),
			paddingRight: rem(baseWidth * 0.4),
		},
		sm: {
			height: rem(height - height * (percentChange / 100)),
			paddingLeft: rem(baseWidth * 0.6),
			paddingRight: rem(baseWidth * 0.6),
		},
		md: {
			height: rem(height),
			paddingLeft: rem(baseWidth),
			paddingRight: rem(baseWidth),
		},
		lg: {
			height: rem(height * 1.1),
			paddingLeft: rem(baseWidth),
			paddingRight: rem(baseWidth),
		},
		xl: {
			height: rem(height * 1.2),
			paddingLeft: rem(baseWidth * 1.2),
			paddingRight: rem(baseWidth * 1.2),
		},
	};

	sizes["compact-xs"] = {
		height: rem(24),
		paddingLeft: rem(8),
		paddingRight: rem(8),
	};
	sizes["compact-sm"] = {
		height: rem(28),
		paddingLeft: rem(10),
		paddingRight: rem(10),
	};
	sizes["compact-md"] = {
		height: rem(30),
		paddingLeft: rem(12),
		paddingRight: rem(12),
	};
	sizes["compact-lg"] = {
		height: rem(32),
		paddingLeft: rem(14),
		paddingRight: rem(14),
	};
	sizes["compact-xl"] = {
		height: rem(36),
		paddingLeft: rem(16),
		paddingRight: rem(16),
	};
	return sizes;
}

const fontSizes = {
	xs: rem(10),
	sm: rem(10),
	md: rem(12),
	lg: rem(14),
	xl: rem(14),
};

interface GetSizeStyles {
	compact: boolean;
	size: string | number;
	withLeftIcon: boolean;
	withRightIcon: boolean;
}

function getSizeStyles({
	compact,
	size,
	withLeftIcon,
	withRightIcon,
}: GetSizeStyles): CSSObject {
	const sizes = getSizes({ height: 42, aspectRatio: 1.3, percentChange: 15 });
	if (compact) {
		return sizes[`compact-${size}`];
	}

	const _sizes: (typeof sizes)[keyof typeof sizes] = sizes[size];

	if (!_sizes) {
		return {};
	}

	return {
		..._sizes,
		paddingLeft: withLeftIcon
			? `calc(${_sizes.paddingLeft}  / 1.5)`
			: _sizes.paddingLeft,
		paddingRight: withRightIcon
			? `calc(${_sizes.paddingRight}  / 1.5)`
			: _sizes.paddingRight,
	};
}

interface GetVariantStyles {
	theme: MantineTheme;
	color: MantineColor;
	variant: string;
	gradient: MantineGradient;
}

const getVariantStyles = ({
	variant,
	theme,
	color,
	gradient,
}: GetVariantStyles) => {
	if (!BUTTON_VARIANTS.includes(variant)) {
		return null;
	}

	const colors = theme.fn.variant({ color, variant, gradient });

	if (variant === "gradient") {
		return {
			border: 0,
			backgroundColor: colors.background,
			color: colors.color,
			...theme.fn.hover({ backgroundSize: "200%" }),
		};
	}

	return {
		border: `${rem(1)} solid ${colors.border}}`,
		backgroundColor: colors.background,
		color: colors.color,
		...theme.fn.hover({ backgroundColor: colors.hover }),
	};
};

interface ModifiedStyledParams {
	theme: MantineTheme;
	variant: string;
	color: MantineColor;
	size: string | number;
	withRightIcon: boolean;
	withLeftIcon: boolean;
}

const modifiedStyles = ({
	theme,
	variant,
	color,

	withLeftIcon,
	withRightIcon,
}: ModifiedStyledParams): CSSObject => {
	// if (variant === "subtle" && color === "dark") {
	// 	return {
	// 		backgroundColor: theme.colors.gray[0],
	// 		"&:not([data-disabled]):hover": {
	// 			backgroundColor: `${theme.colors.gray[1]}`,
	// 		},
	// 	};
	// }

	if (variant === "outline") {
		return {
			position: "relative",
			overflow: "hidden",
			transition: `color 0.24s ${theme.transitionTimingFunction}`,
			border:
				color === "white"
					? `${rem(1)} solid ${theme.colors.gray[0]}`
					: undefined,
			color: color === "white" ? theme.colors.gray[0] : undefined,

			"&:after": {
				content: `""`,
				backgroundColor:
					color === "white"
						? theme.colors.gray[0]
						: theme.colors[color][6],
				width: "100%",
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				transform: "translateY(105%)",
				transition: `transform 0.12s ${theme.transitionTimingFunction}`,
			},

			"&:hover::after": {
				transform: "translateY(0)",
				transition: `transform 0.32s ${theme.transitionTimingFunction}`,
				zIndex: -1,
			},

			"&:not([data-disabled]):hover": {
				color:
					color === "white" ? theme.colors.dark[7] : theme.colors.gray[0],
				backgroundColor: "transparent",
				zIndex: 1,
			},
		};
	}

	if (variant === "subtle" && color === "dark") {
		return {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.fn.rgba(theme.colors.gray[0], 0)
					: theme.fn.rgba(theme.colors.dark[7], 0),
			"&:not([data-disabled]):hover": {
				backgroundColor:
					theme.colorScheme === "dark"
						? theme.fn.rgba(theme.colors.gray[1], 0.04)
						: theme.fn.rgba(theme.colors.dark[1], 0.04),
			},
		};
	}

	if (variant === "light" && color === "dark") {
		return {
			backgroundColor: theme.colors.gray[1],
			"&:not([data-disabled]):hover": {
				backgroundColor: theme.colors.gray[2],
			},
		};
	}

	if (withLeftIcon || withRightIcon) {
	}

	if (variant === "filled" && color === "light") {
		return {
			backgroundColor: theme.colors.gray[0],
			color: theme.colors.dark[7],

			"&:not([data-disabled]):hover": {
				backgroundColor: theme.fn.lighten(theme.colors.gray[0], 0.05),
			},
		};
	}

	return null;
};

export const Button: MantineThemeOverride["components"]["Button"] = {
	defaultProps: {
		color: "dark",
		uppercase: true,
	},
	styles: (
		theme,
		{ compact, withLeftIcon, withRightIcon, color },
		{ variant, size }
	) => ({
		root: {
			...getSizeStyles({ compact, size, withLeftIcon, withRightIcon }),
			// fontSize: getSize({ size, sizes: theme.fontSizes }),
			fontSize: fontSizes[size],
			letterSpacing: 1,
			...getVariantStyles,
			...modifiedStyles({
				theme,
				variant,
				color,
				size,
				withLeftIcon,
				withRightIcon,
			}),

			// svg: {
			//   strokeWidth: 1,
			// },
		},
		label: {
			fontWeight: 500,
		},
	}),
};
