import { attributeKeys } from "@constants/attributeKeys";
import { useMemo } from "react";
import {
	AttributeData,
	ProductAttribute,
	ProductBase,
	ProductDefaultAttribute,
} from "src/types/product.types";

export interface FormatAttributesProps {
	type: string;
	defaultWpAttributes: Array<ProductDefaultAttribute>;
	attributes?: ProductAttribute[];
	bundledProducts: ProductBase[];
}

export interface StandardAttributeKeys {
	[key: string]: AttributeData[]; //these keys are the attributeKeys || attribute.name
}

export interface BundleAttributes {
	[key: ProductBase["wcProductId"]]: StandardAttributeKeys; //this key is the wcProductId
}

export interface FormattedAttributes {
	type: string;
	// colors?: AttributeData[];
	standard?: StandardAttributeKeys;
	bundle?: BundleAttributes;
	bundleItems: { [key: ProductBase["wcProductId"]]: ProductBase };
	defaultAttributes: { [key: string]: string };
	defaultBundleAttributes: {
		[key: ProductBase["wcProductId"]]: { [key: string]: string };
	};
}

export const useFormatAttributes = (
	props: FormatAttributesProps
): FormattedAttributes => {
	const { type, attributes, bundledProducts, defaultWpAttributes } = props;

	let standard: StandardAttributeKeys = {};

	let bundle: BundleAttributes = {};

	attributes?.forEach((attribute) => {
		let key = attributeKeys[attribute.id] || attribute.name;

		const filteredData = attribute.data
			.filter((item) => attribute.options.includes(item.name))
			.map((item) => ({
				...item,
				id: attribute.id,
			}))
			.sort(
				(a, b) =>
					attribute.options.indexOf(a.name) -
					attribute.options.indexOf(b.name)
			);

		standard[key] = filteredData;
	});

	bundledProducts &&
		bundledProducts.forEach((bundleProduct) => {
			const wcProductId = bundleProduct.wcProductId;
			const bundleAttributes: StandardAttributeKeys = {};

			bundleProduct.attributes?.forEach((attribute) => {
				let key = attributeKeys[attribute.id] || attribute.name;

				const filteredData = attribute.data
					.filter((item) => attribute.options.includes(item.name))
					.map((item) => ({
						...item,
						id: attribute.id,
					}))
					.sort(
						(a, b) =>
							attribute.options.indexOf(a.name) -
							attribute.options.indexOf(b.name)
					);

				if (!(key in standard)) {
					bundleAttributes[key] = filteredData;
				}
			});

			bundle[wcProductId] = bundleAttributes;
		});

	const defaultAttributes = useMemo(() => {
		const defaultAttrs = {};

		if (defaultWpAttributes?.length > 0) {
			defaultWpAttributes.forEach((defaultAttr) => {
				const { id, name, option } = defaultAttr;
				const attributeKey = attributeKeys[id] || name;

				if (attributeKey && option) {
					defaultAttrs[attributeKey] = option;
				}
			});
		} else {
			Object.keys(standard).forEach((attributeKey) => {
				const attributeList = standard[attributeKey];
				const lowestOption = attributeList[0]?.name;

				if (lowestOption) {
					defaultAttrs[attributeKey] = lowestOption;
				}
			});
		}

		bundledProducts &&
			bundledProducts?.forEach((bundleProduct) => {
				if (bundleProduct.default_attributes) {
					bundleProduct.default_attributes.forEach((defaultAttr) => {
						const { id, name, option } = defaultAttr;
						const attributeKey = attributeKeys[id] || name;

						if (attributeKey && option && attributeKey in standard) {
							defaultAttrs[attributeKey] = option;
						}
					});
				}
			});

		return defaultAttrs;
	}, [attributes, defaultWpAttributes, bundledProducts]);

	const defaultBundleAttributes = useMemo(() => {
		const defaultBundleAttrs: {
			[key: ProductBase["wcProductId"]]: { [key: string]: string };
		} = {};

		bundledProducts &&
			bundledProducts?.forEach((bundleProduct) => {
				const wcProductId = bundleProduct.wcProductId;

				const bundleDefaultAttrs = {};

				if (bundleProduct.default_attributes) {
					bundleProduct.default_attributes.forEach((defaultAttr) => {
						const { id, name, option } = defaultAttr;
						const attributeKey = attributeKeys[id] || name;

						if (attributeKey && option) {
							bundleDefaultAttrs[attributeKey] = option;
						}
					});
				}

				Object.keys(bundleDefaultAttrs).forEach((key) => {
					if (key in defaultAttributes) {
						delete bundleDefaultAttrs[key];
					}
				});

				Object.keys(bundle[wcProductId]).forEach((attributeKey) => {
					if (!(attributeKey in bundleDefaultAttrs)) {
						const attributeList = bundle[wcProductId][attributeKey];
						const lowestOption = attributeList[0]?.name;
						if (lowestOption) {
							bundleDefaultAttrs[attributeKey] = lowestOption;
						}
					}
				});

				defaultBundleAttrs[wcProductId] = bundleDefaultAttrs;
			});

		return defaultBundleAttrs;
	}, [bundledProducts]);

	const bundleItems = bundledProducts
		? bundledProducts.reduce((acc, product) => {
				acc[product.wcProductId] = product;
				return acc;
		  }, {})
		: {};

	return {
		type,
		standard: standard,
		bundle,
		defaultAttributes,
		defaultBundleAttributes,
		bundleItems,
	};
};
