import { createStyles, getStylesRef, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
	},

	colorSchemeRoot: {
		cursor: "pointer",
	},

	colorSchemeInput: {
		[`&:checked+*>.${getStylesRef("thumb")}`]: {
			backgroundColor: theme.colors.dark[7],
			borderColor: theme.colors.dark[4],
			cursor: "pointer",
		},

		[`&:checked+.${getStylesRef("track")}`]: {
			background: theme.colors.dark[5],
			borderColor: theme.colors.dark[4],
			cursor: "pointer",
		},
	},

	colorSchemeThumb: {
		ref: getStylesRef("thumb"),
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.dark[6]
				: theme.colors.gray[0],
		cursor: "pointer",
		borderColor: theme.colors.gray[2],
	},

	colorSchemeTrack: {
		ref: getStylesRef("track"),
		backgroundColor: theme.colors.gray[1],
		cursor: "pointer",
		borderColor: theme.colors.gray[2],
	},

	actionIcon: {
		marginLeft: rem(8),
		"&:active": {
			...theme.activeStyles,
		},

		svg: {
			display: "block",
		},

		"&:nth-of-type(1)": {
			marginLeft: rem(18),
		},
	},

	drawer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},

	drawerContent: {
		flexGrow: 1,
		overflowY: "hidden",
	},

	btnWrapper: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing.xs,
	},
}));
