import {
	MantineThemeOverride,
	CSSObject,
	TextProps,
	rem,
	ContextStylesParams,
} from "@mantine/core";

type ExtendedCustomText = "subtitle" | TextProps[];

const modifiedStyles = ({ size, variant }): CSSObject => {
	const modifiedStyles = {};

	size === "xs" && variant === "subtitle"
		? (modifiedStyles["fontSize"] = rem(10))
		: undefined;
	size === "sm" && variant === "subtitle"
		? (modifiedStyles["fontSize"] = rem(11))
		: undefined;
	size === "md" && variant === "subtitle"
		? (modifiedStyles["fontSize"] = rem(12))
		: undefined;
	size === "lg" && variant === "subtitle"
		? (modifiedStyles["fontSize"] = rem(14))
		: undefined;
	size === "xl" && variant === "subtitle"
		? (modifiedStyles["fontSize"] = rem(16))
		: undefined;

	return modifiedStyles;
};

export const Text: MantineThemeOverride["components"]["Text"] = {
	defaultProps: {
		size: "md",
	},
	variants: {
		subtitle: (theme) => ({
			root: {
				textTransform: "uppercase",
				fontWeight: 600,
				letterSpacing: 0.42,
			},
		}),
	},

	styles: (theme, { color }, { size, variant }) => ({
		root: {
			...modifiedStyles({ size, variant }),
			// color: color === "dimmed" ? theme.colors.brandTertiary[4] : undefined,
			color:
				color === "dimmed"
					? theme.colorScheme === "dark"
						? theme.fn.darken(theme.colors.dark[0], 0.2)
						: theme.colors.gray[7]
					: undefined,
		},
	}),
};
